import {
  CollectionsApi,
  createCollectionsApi as createWixCodeCollectionsApi,
} from '@wix/wix-code-collections-api'
import createEditorTransportLayer from '@wix/wix-code-collections-transport-editor'
import { DynamicAppSchemaTransport } from '@wix/wix-data-dynamic-app-collection-transport'
import { SchemaOperation } from '@wix/wix-code-collections-api/lib/types'
import Experiments from '@wix/wix-experiments'

export const FORMS_NS = 'Forms/'

export const initCollectionsApi = ({
  editorSDK,
  appDefinitionId,
  httpClient,
  instance,
  experiments,
}: {
  editorSDK: EditorSDK
  appDefinitionId: string
  httpClient: any
  instance: string
  experiments: Experiments
}) => {
  const nativeTransportLayer = createEditorTransportLayer(editorSDK, appDefinitionId)
  const dynamicTransportLayer = new DynamicAppSchemaTransport(
    instance,
    httpClient,
    '/_serverless/forms-schema-gateway',
  )
  const nativeApi = createWixCodeCollectionsApi({
    transportLayer: nativeTransportLayer,
    ownerAppId: appDefinitionId,
  })
  const dynamicApi = createWixCodeCollectionsApi({
    transportLayer: dynamicTransportLayer,
    ownerAppId: appDefinitionId,
  })
  return new WixCodeCollectionApi(nativeApi, dynamicApi, experiments)
}

export class WixCodeCollectionApi implements CollectionsApi {
  constructor(private readonly native: CollectionsApi, private readonly dynamic: CollectionsApi, private readonly experiments: Experiments) {}

  _isDynamic = (collectionId: string) => this.dynamic && collectionId.startsWith(FORMS_NS)

  execute = (operation: SchemaOperation) =>
    this._isDynamic(operation.collectionId)
      ? this.dynamic.execute(operation)
      : this.native.execute(operation)

  getReferring = (collectionId: string) =>
    this._isDynamic(collectionId)
      ? this.dynamic.getReferring(collectionId)
      : this.native.getReferring(collectionId)

  get = (collectionId: string) =>
    this._isDynamic(collectionId) ? this.dynamic.get(collectionId) : this.native.get(collectionId)

  getSome = (collectionIds: string) => this.native.getSome(collectionIds)
  getAll = () => {
    const shouldUseDynamicAppSchemas = this.experiments.enabled(
      'specs.crm.FormsBuilderDynamicAppSchema',
    )

    if (shouldUseDynamicAppSchemas) {
      return this.dynamic.getAll()
    } else {
      return this.native.getAll()
    }
  }
}
