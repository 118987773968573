import { FormPlugin } from '@wix/forms-common'
import { FormsFieldPreset } from '@wix/forms-common/dist/src'
import { Layout, ResponsiveLayout } from '@wix/platform-editor-sdk'
import _ from 'lodash'
import { FormPreset } from '../../../constants/form-types'
import {
  COMPLEX_ADDRESS_ROLES,
  COMPLEX_CONTAINER_ROLES,
  COMPLEX_FIELDS_INNER_FIELDS,
  COMPLEX_FIELDS_ROLES,
  FIELDS,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_LIMIT_MESSAGE,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_SUBTITLE,
  ROLE_TITLE,
  THANK_YOU_STEP_ROLE,
} from '../../../constants/roles'
import translations from '../../../utils/translations'
import { escapeRegExp, innerText, getFirstTextSegment } from '../../../utils/utils'
import { calcNewPhoneLayout } from '../complex-phone/utils'
import { mapComplexInnerFieldTypeToServerCrmType } from '../contact-sync/utils'
import { getDefaultFieldName } from '../fields/utils'
import { createItemLayout, findNewFieldStackOrder } from '../layout/responsive-utils'
import { ComplexFieldExtraData, FieldExtraData } from '../preset/fields/field-types-data'
import { getFieldPreset, getFormPreset } from '../preset/preset-service'
import { getPrimaryConnectionFromStructure, isInputField } from '../utils'
import { CommonStyles, getFieldStyle } from './form-style-service'
import {
  createFieldWithMostCommonLayout,
  relayoutInnerComponentsComplexAddress,
} from './layout-service'

interface FetchHiddenMessageParams {
  newMessage?: string
  formLayout: string
  preset: string
  locale: string
  fallbackSchema: Object
  role?: string
  itemLayoutType?: ResponsiveItemLayoutType
  layoutData?: ResponsiveLayoutData
}

interface CreateFieldPropsBase {
  preset: string
  fieldType: FieldPreset
  commonStyles: CommonStyles
  fieldsData: FormField[] | undefined
  formWidth?: number
  isResponsive?: boolean
  responsiveItemLayoutType?: ResponsiveItemLayoutType
  layout?: any | ResponsiveLayout
  plugins: FormPlugin[]
  controllerId?: string
}

interface CreateFieldProps extends CreateFieldPropsBase {
  extraData: FieldExtraData
  globalSimilarity: boolean
}

interface CreateInnerFieldProps extends CreateFieldProps {
  formControllerId: string
  complexControllerId: string
  sameComplexFieldsOnStage: FormField[]
}

interface CreateComplexFieldProps extends CreateFieldPropsBase {
  extraData: ComplexFieldExtraData
  fieldComponent?: Partial<RawComponentStructure>
  formConfig: Partial<ComponentConfig>
  sameComplexFieldsOnStage: FormField[]
}

interface CreateComplexInnerFieldsProps {
  widgetRole: string
  innerFieldPresets: FormsFieldPreset[]
  complexControllerId: string
  createWithAllFields?: boolean
}

const getComponentFromPreset = (ravenInstance) => async (
  { role, preset, locale },
  onFailedPresetCallback,
) => {
  if (!preset) {
    return
  }
  const rawPreset = await fetchPreset(ravenInstance)(preset, locale, onFailedPresetCallback)
  if (!rawPreset) {
    return
  }
  const components = rawPreset.components
  const roleSchema = _recursiveFindComponentSchema(components, role)
  return roleSchema
}

const _recursiveFindComponentSchema = (components, role) => {
  for (const comp of components) {
    if (comp.role === role) {
      return comp
    } else if (comp.components) {
      const subcomp = _recursiveFindComponentSchema(comp.components, role)
      if (subcomp) {
        return subcomp
      }
    }
  }
}

const _constructComponentWithoutRoles = (component: RawComponentStructure, roles: string[]) => {
  const shouldRemoveComponent = !component || roles.includes(component.role)
  if (shouldRemoveComponent) {
    return null
  } else {
    return component.components
      ? {
          ...component,
          components: component.components
            .map((comp) => _constructComponentWithoutRoles(comp as RawComponentStructure, roles))
            .filter((c) => c),
        }
      : component
  }
}

const convertToInnerStructure = ({ role, subRole, connectionConfig, ...rest }: any) => ({
  role,
  subRole,
  connectionConfig,
  data: rest,
})

export const getExtraMessageText = ({ data, presetKey = '', newMessage }) => {
  const html = getFirstTextSegment(data.text)

  const parsedMessage = innerText(html)
  const newText =
    newMessage ||
    (presetKey === FormPreset.REGISTRATION_FORM
      ? translations.t('settings.errorMessage.registrationForm')
      : parsedMessage)

  return {
    text: html
      .replace(/\s*<br\/>\s*/g, '') // remove inner tags to let regex match
      .replace(new RegExp(`>${escapeRegExp(innerText(html))}`), `>${newText}`),
  }
}

const getChildComponents = (presetKey, comp, newMessage?) =>
  comp.components &&
  comp.components.map((childComp) =>
    deConstructComponent({ presetKey, rawComp: childComp, newMessage }),
  )

const deConstructComponent = ({ presetKey, rawComp, newMessage = null }: formComponent) => {
  const comp = rawComp
  comp.connectionConfig = _.merge({}, comp.config, comp.connectionConfig)
  if (_.includes([ROLE_MESSAGE, ROLE_DOWNLOAD_MESSAGE, ROLE_LIMIT_MESSAGE], comp.role)) {
    comp.data = _.merge(
      {},
      comp.data,
      getExtraMessageText({ data: comp.data, presetKey, newMessage }),
    )
  }
  comp.components = getChildComponents(presetKey, comp, newMessage)
  return comp
}

export const fetchPreset = (ravenInstance) => async (
  presetKey: FormPresetName,
  locale: string = 'en',
  onFailedPresetCallback: Function = _.noop,
): Promise<RawComponentStructure | undefined> => {
  let rawPreset
  try {
    rawPreset = await getFormPreset(ravenInstance)(presetKey, locale)
  } catch (e) {
    await onFailedPresetCallback(`${presetKey}: ${(e as Error).message}`)
    return
  }
  return rawPreset
}

export const createAndConnectInnerComplexField = (createInnerFieldProps: CreateInnerFieldProps) => {
  const innerFieldStructure = createField(createInnerFieldProps)
  if (_.includes(_.values(COMPLEX_ADDRESS_ROLES), innerFieldStructure.role)) {
    const sameTypeFields = _.reduce(
      createInnerFieldProps.sameComplexFieldsOnStage,
      (acc, complexField) => {
        const sameFieldType = _.find(complexField.childFields, {
          fieldType: createInnerFieldProps.fieldType,
        })
        return _.compact([...acc, sameFieldType])
      },
      [],
    )
    const { connectionConfig } = innerFieldStructure

    const crmLabel = getDefaultFieldName({
      fieldStructure: innerFieldStructure,
      fieldsOnStage: sameTypeFields,
    })
    const crmType =
      mapComplexInnerFieldTypeToServerCrmType.complexAddress[connectionConfig.fieldType]

    innerFieldStructure.connectionConfig = {
      ...connectionConfig,
      crmLabel,
      collectionFieldKey: _.camelCase(crmLabel),
      crmType,
    }
  }

  const { formControllerId, complexControllerId } = createInnerFieldProps

  return connectComponentToControllers(
    {
      ...innerFieldStructure.data,
      config: innerFieldStructure.connectionConfig,
      role: innerFieldStructure.role,
    },
    [{ id: formControllerId }, { id: complexControllerId, isPrimary: true }],
  )
}

// if we want to copy & paste a previous layout we must make sure it has all fields
export const getPreviousComplexComponent = (
  role: string,
  fields: FormField[],
  expectedFields: number,
) => fields.find((f) => f.role === role && f.childFields.length === expectedFields)

const _createComplexInnerFields = (
  createComplexFieldProps: CreateComplexFieldProps,
  { widgetRole, innerFieldPresets, complexControllerId }: CreateComplexInnerFieldsProps,
) => {
  const { fieldsData, controllerId, extraData } = createComplexFieldProps

  const prevComplexInnerFields = _.compact(
    fieldsData.filter((f) => f.role === widgetRole).flatMap((f) => f.childFields),
  )
  const fieldsDataInspiration = [...fieldsData, ...prevComplexInnerFields]

  const structures = innerFieldPresets.map((innerFieldType) =>
    createAndConnectInnerComplexField({
      ...createComplexFieldProps,
      fieldType: innerFieldType,
      formControllerId: controllerId,
      layout: null,
      complexControllerId,
      extraData: extraData[innerFieldType] || {},
      fieldsData: fieldsDataInspiration,
      globalSimilarity: true,
    }),
  )
  return structures
}

const _createComplexField = ({
  innerComponents,
  fieldComponent,
  complexControllerId,
  customWidgetRole,
}: {
  innerComponents: RawComponentStructure[]
  fieldComponent
  complexControllerId: string
  customWidgetRole: string
}) => {
  const fieldWidget = _.cloneDeep(fieldComponent)
  const containerWithConnection = connectComponent(
    {
      ...fieldWidget.components[0],
      config: {},
      role: customWidgetRole,
    },
    complexControllerId,
  )
  containerWithConnection.components = innerComponents
  fieldWidget.components[0] = containerWithConnection as any
  return convertToInnerStructure({
    ...fieldWidget,
    data: {
      ...fieldWidget.data,
      id: complexControllerId,
    },
  })
}

export const getFieldLayout = (field: FormField): FieldLayout => ({
  x: field.x,
  y: field.y,
  height: field.height,
  width: field.width,
})

const createComplexPhoneField = (createComplexFieldProps: CreateComplexFieldProps) => {
  const complexControllerId = 'phone-controller-placeholder-id'
  const innerFieldPresets = [
    FormsFieldPreset.COMPLEX_PHONE_DROPDOWN,
    FormsFieldPreset.COMPLEX_PHONE_TEXT,
  ]

  const [dropdownField, phoneTextField] = _createComplexInnerFields(createComplexFieldProps, {
    innerFieldPresets,
    complexControllerId,
    widgetRole: FIELDS.ROLE_FIELD_COMPLEX_PHONE_WIDGET,
  })

  const { complexPhoneLayout, childFieldsWithCorrectLayout } = calcNewPhoneLayout(
    createComplexFieldProps.fieldsData,
    createComplexFieldProps.fieldComponent,
    dropdownField,
    phoneTextField,
    createComplexFieldProps.formWidth,
  )

  const complexComponent = _createComplexField({
    innerComponents: childFieldsWithCorrectLayout as any,
    complexControllerId,
    customWidgetRole: COMPLEX_CONTAINER_ROLES.PHONE,
    fieldComponent: {
      ...createComplexFieldProps.fieldComponent,
      layout: complexPhoneLayout,
    },
  })
  return complexComponent
}

const createComplexAddressField = (createComplexFieldProps: CreateComplexFieldProps) => {
  const complexControllerId = 'address-controller-placeholder-id'
  const innerFieldPresets = [
    FormsFieldPreset.COMPLEX_ADDRESS_STREET,
    FormsFieldPreset.COMPLEX_ADDRESS_STREET_2,
    FormsFieldPreset.COMPLEX_ADDRESS_CITY,
    FormsFieldPreset.COMPLEX_ADDRESS_STATE,
    FormsFieldPreset.COMPLEX_ADDRESS_ZIPCODE,
    FormsFieldPreset.COMPLEX_ADDRESS_COUNTRY,
  ]
  const innerComponents = _createComplexInnerFields(createComplexFieldProps, {
    innerFieldPresets,
    complexControllerId,
    widgetRole: FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET,
  })

  // relayout
  const innerComponentsLayouted = relayoutInnerComponentsComplexAddress({
    fieldComponents: innerComponents as any,
    isResponsive: createComplexFieldProps.isResponsive,
    formConfig: createComplexFieldProps.formConfig,
    formWidth: createComplexFieldProps.formWidth,
  })

  const complexComponent = _createComplexField({
    innerComponents: innerComponentsLayouted as any,
    complexControllerId,
    customWidgetRole: COMPLEX_CONTAINER_ROLES.ADDRESS,
    fieldComponent: createComplexFieldProps.fieldComponent,
  })

  return complexComponent
}

export const createComplexField = (createComplexFieldProps: CreateComplexFieldProps) => {
  const { extraData, fieldType } = createComplexFieldProps
  const fieldComponent = getFieldComponent({
    ...createComplexFieldProps,
    extraData: extraData[fieldType] || {},
    globalSimilarity: false,
  })
  const props = {
    ...createComplexFieldProps,
    fieldComponent,
  }
  let complexComponent
  if (fieldType === FormsFieldPreset.COMPLEX_PHONE_WIDGET) {
    complexComponent = createComplexPhoneField(props)
  }
  if (fieldType === FormsFieldPreset.COMPLEX_ADDRESS_WIDGET) {
    complexComponent = createComplexAddressField(props)
  }

  if (complexComponent) {
    const componentBoundaries = _getBoundingBox(
      complexComponent.data.components[0].components.map((c) => c.layout),
    )
    complexComponent.data.layout.width = componentBoundaries.width
    complexComponent.data.layout.height = componentBoundaries.height
    return complexComponent
  }
}

const _getBoundingBox = (
  innerFields: { width: number; height: number; x: number; y: number }[],
) => {
  let maxWidth = 0
  let maxHeight = 0

  innerFields.forEach((f) => {
    if (f.x + f.width > maxWidth) {
      maxWidth = f.x + f.width
    }

    if (f.y + f.height > maxHeight) {
      maxHeight = f.y + f.height
    }
  })

  return { width: maxWidth, height: maxHeight }
}

const getFieldComponent = ({
  preset,
  fieldType,
  extraData,
  fieldsData,
  formWidth,
  isResponsive,
  responsiveItemLayoutType,
  layout,
  plugins,
}: CreateFieldProps) => {
  // TODO remove presetKey
  const responsiveLayoutData =
    isResponsive && responsiveItemLayoutType !== 'GridItemLayout'
      ? findNewFieldStackOrder(fieldsData)
      : null

  const rawPreset = getFieldPreset({
    fieldType,
    extraData,
    plugins,
    isResponsive,
    responsiveItemLayoutType,
    responsiveLayoutData,
  })

  if (isResponsive) {
    return _.merge({}, deConstructComponent({ presetKey: preset, rawComp: rawPreset }), {
      layoutResponsive: { ...layout },
    })
  } else {
    const width = Math.min(
      formWidth - _.get(layout, 'x', 0),
      _.get(layout, 'width') || rawPreset.layout.width,
    )
    return _.merge({}, deConstructComponent({ presetKey: preset, rawComp: rawPreset }), {
      layout: { ...layout, width },
    })
  }
}

// return { role, connectionConfig, data  }
export const createField = (createFieldProps: CreateFieldProps) => {
  const { isResponsive, commonStyles, fieldType, fieldsData } = createFieldProps

  const fieldComponent = getFieldComponent(createFieldProps)

  const fieldStyle = getFieldStyle(commonStyles, fieldType)
  if (isResponsive) {
    _.assign(fieldComponent.style.stylesInBreakpoints[0].style.properties, fieldStyle)
  } else {
    _.assign(fieldComponent.style.style.properties, fieldStyle)
  }

  return fieldsData
    ? convertToInnerStructure(
        createFieldWithMostCommonLayout(
          fieldType,
          fieldsData,
          fieldComponent,
          createFieldProps.globalSimilarity,
          isResponsive,
        ),
      )
    : convertToInnerStructure(fieldComponent)
}

const restoreFieldSchema = (ravenInstance) => async (
  { role, preset, locale, fallbackSchema },
  onFailedCallback,
): Promise<{ rawSchema; fallback }> => {
  const rawSchema = await getComponentFromPreset(ravenInstance)(
    {
      role,
      preset,
      locale,
    },
    onFailedCallback,
  )

  if (rawSchema) {
    return { rawSchema, fallback: false }
  } else {
    return { rawSchema: fallbackSchema, fallback: true }
  }
}

export const fetchThankYouStepSchema = (ravenInstance) => async ({ preset, locale }) => {
  const { rawSchema } = await restoreFieldSchema(ravenInstance)(
    { role: THANK_YOU_STEP_ROLE, preset, locale, fallbackSchema: {} },
    (reason) => (this as any).coreApi.logFetchPresetsFailed(null, reason),
  )

  return _constructComponentWithoutRoles(rawSchema, [ROLE_MESSAGE, ROLE_DOWNLOAD_MESSAGE])
}

export const fetchSubmitButtonSchema = (ravenInstance) => async (
  {
    label,
    preset,
    locale,
    fallbackSchema,
    itemLayoutType,
    layoutData,
  }: {
    label: string
    preset: string
    locale: string
    fallbackSchema: Object
    itemLayoutType?: ResponsiveItemLayoutType
    layoutData?: ResponsiveLayoutData
  },
  onFailedCallback,
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    { role: ROLE_SUBMIT_BUTTON, preset, locale, fallbackSchema },
    onFailedCallback,
  )

  let extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  if (itemLayoutType) {
    extraData = _.merge({}, extraData, {
      layoutResponsive: createItemLayout({
        itemLayoutType,
        layoutData,
      }),
    })
  }

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchMultiStepNavigationButtonSchema = (ravenInstance) => async (
  { label, preset, locale, fallbackSchema, role },
  onFailedCallback,
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    { role, preset, locale, fallbackSchema },
    onFailedCallback,
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchLoginLinkSchema = (ravenInstance) => async (
  { label, preset, locale, fallbackSchema },
  onFailedCallback,
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      preset,
      locale,
      fallbackSchema,
    },
    onFailedCallback,
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const loginLinkComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(loginLinkComponent)
}

export const fetchHiddenMessage = (ravenInstance) => async (
  {
    newMessage,
    formLayout,
    preset,
    locale,
    fallbackSchema,
    role = ROLE_SUBMIT_BUTTON,
    itemLayoutType,
    layoutData,
  }: FetchHiddenMessageParams,
  onFailedCallback,
) => {
  const { rawSchema } = await restoreFieldSchema(ravenInstance)(
    { role, preset, locale, fallbackSchema },
    onFailedCallback,
  )

  return getMessageSchema({
    rawSchema,
    itemLayoutType,
    layoutData,
    newMessage,
    formLayout,
    role,
  })
}

export const fetchHiddenMessageAndReplaceRole = (ravenInstance) => async (
  fetchHiddenSchemaProps: FetchHiddenMessageParams,
  onFailedCallback,
  replaceByRole: string,
) => {
  const hiddenMessage = await fetchHiddenMessage(ravenInstance)(
    fetchHiddenSchemaProps,
    onFailedCallback,
  )

  return {
    ...hiddenMessage,
    role: replaceByRole,
  }
}

export const getMessageSchema = async ({
  rawSchema,
  newMessage,
  formLayout,
  role,
  itemLayoutType,
  layoutData,
}: {
  rawSchema: any
  newMessage?: string
  formLayout: any
  role: string
  itemLayoutType?: ResponsiveItemLayoutType
  layoutData?: ResponsiveLayoutData
}) => {
  const isCenterAligned =
    rawSchema.data.text.match(/text-align:[\s]*center/) && rawSchema.layout.x === 0
  const messageWidth = isCenterAligned ? formLayout.width : rawSchema.layout.width

  const layout: { layout: any } | { layoutResponsive: ResponsiveLayout } = itemLayoutType
    ? {
        layoutResponsive: createItemLayout({
          itemLayoutType,
          layoutData,
        }),
      }
    : { layout: { width: messageWidth } }

  const hiddenMessageComponent = _.merge(
    {},
    deConstructComponent({
      rawComp: { ...rawSchema, role },
      newMessage,
    }),
    layout,
  )

  return convertToInnerStructure(hiddenMessageComponent)
}

const createWidgetResponsiveLayout = (
  parentLayoutResponsive: Partial<ResponsiveLayout>,
): ResponsiveLayout => {
  const defaultComponentLayouts = [
    {
      type: 'ComponentLayout',
      height: {
        type: 'auto',
      },
      width: {
        type: 'percentage',
        value: 30,
      },
      minWidth: {
        type: 'px',
        value: 320,
      },
      hidden: false,
      breakpoint: undefined,
    },
  ]

  const componentLayouts = _.get(
    parentLayoutResponsive,
    'componentLayouts',
    defaultComponentLayouts,
  ) as ResponsiveLayout['componentLayouts']

  const layoutResponsive: ResponsiveLayout = {
    id: '',
    type: 'LayoutData',
    itemLayouts: [
      {
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        margins: {
          left: {
            type: 'px',
            value: 0,
          },
          right: {
            type: 'px',
            value: 0,
          },
          top: {
            type: 'px',
            value: 0,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
        alignSelf: 'start',
        justifySelf: 'center',
        breakpoint: undefined,
      },
    ],
    componentLayouts,
    containerLayouts: [
      {
        type: 'GridContainerLayout',
        rows: [
          {
            value: 1,
            type: 'fr',
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        breakpoint: undefined,
      },
    ],
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
    },
  }

  return layoutResponsive
}

export const convertPreset = (
  structureWithOptionalExtraPayload: RawComponentStructure,
  { controllerId, coords = {}, appWidgetStructure = null, width = null, space = null },
): ComponentStructure => {
  const parentLayoutResponsive = _.get(structureWithOptionalExtraPayload, 'parentLayoutResponsive')
  const structure = _.omit(structureWithOptionalExtraPayload, [
    'parentLayoutResponsive',
    'breakpoints',
  ]) as RawComponentStructure
  const formAfterWidthChange = width ? changeFormWidth(structure, width, space) : structure
  const presetLayout = _.merge({}, formAfterWidthChange.layout, coords)
  const presetLayoutResponsive = structure.layoutResponsive
    ? {
        layoutResponsive:
          appWidgetStructure && appWidgetStructure.layoutResponsive
            ? appWidgetStructure.layoutResponsive
            : createWidgetResponsiveLayout(parentLayoutResponsive),
      }
    : {}
  const mobileLayout = _.get(structure.mobileStructure, 'layout')
  const rootComponent = connectComponents(
    {
      ...formAfterWidthChange,
      layout: appWidgetStructure ? _.merge({}, presetLayout, { x: 0, y: 0 }) : presetLayout,
      behaviors: appWidgetStructure ? undefined : structure.behaviors,
      activeModes: appWidgetStructure ? undefined : structure.activeModes,
      modes: appWidgetStructure ? undefined : structure.modes,
      ...(mobileLayout
        ? {
            mobileStructure: {
              layout: appWidgetStructure ? { ...mobileLayout, x: 0, y: 0 } : mobileLayout,
            },
          }
        : {}),
    },
    controllerId,
  )

  return appWidgetStructure
    ? {
        ...appWidgetStructure,
        style: 'appWidget1',
        components: [rootComponent],
        data: { ...appWidgetStructure.data, id: controllerId },
        layout: presetLayout,
        ...presetLayoutResponsive,
        ...(structure.behaviors ? { behaviors: structure.behaviors } : {}),
        ...(structure.activeModes ? { activeModes: structure.activeModes } : {}),
        ...(structure.modes ? { modes: structure.modes } : {}),
        ...(_.get(structure.mobileHints, 'hidden')
          ? { mobileHints: { hidden: true, type: 'MobileHints' } }
          : {}),
        ...(mobileLayout
          ? {
              mobileStructure: {
                layout: mobileLayout,
              },
            }
          : {}),
      }
    : rootComponent
}

const convertComponent = (
  componentStructure: RawComponentStructure,
  mainControllerId: string,
  subControllerId?: string,
) =>
  COMPLEX_FIELDS_INNER_FIELDS.includes(componentStructure.role)
    ? connectComponentToControllers(componentStructure, [
        { id: mainControllerId, isPrimary: false },
        { id: subControllerId, isPrimary: true },
      ])
    : COMPLEX_CONTAINER_ROLES.ADDRESS === componentStructure.role ||
      COMPLEX_CONTAINER_ROLES.PHONE === componentStructure.role
    ? connectComponent(componentStructure, subControllerId)
    : connectComponent(componentStructure, mainControllerId)

const connectComponents = (
  componentStructure: RawComponentStructure,
  mainControllerId: string,
  subControllerId?: string,
) => {
  const convertedComponent = convertComponent(componentStructure, mainControllerId, subControllerId)

  if (!convertedComponent.components) {
    return convertedComponent
  }

  if (COMPLEX_FIELDS_ROLES.includes(componentStructure.role)) {
    // already connected do nothing, TODO: create createComplexField without connecting send as RawComponentStructure
    if (convertedComponent?.data?.id) {
      return convertedComponent
    }
    const complexFieldControllerId = componentStructure?.data?.controllerType
    return {
      ...convertedComponent,
      data: { ...convertedComponent.data, id: complexFieldControllerId },
      components: convertedComponent.components.map((c) =>
        connectComponents(c as RawComponentStructure, mainControllerId, complexFieldControllerId),
      ),
    }
  } else {
    return {
      ...convertedComponent,
      components: convertedComponent.components.map((c) =>
        connectComponents(c as RawComponentStructure, mainControllerId, subControllerId),
      ),
    }
  }
}

export const connectComponent = (
  componentStructure: RawComponentStructure,
  controllerId: string,
  isPrimaryConnection: boolean = true,
) =>
  componentStructure.role
    ? {
        ..._.omit(componentStructure, ['role', 'config']),
        connections: {
          type: 'ConnectionList',
          items: [
            ..._.get(componentStructure, 'connections.items', []),
            {
              type: 'ConnectionItem',
              role: componentStructure.role,
              isPrimary: isPrimaryConnection,
              config: JSON.stringify(componentStructure.config),
              controllerId,
              ...(componentStructure.subRole ? { subRole: componentStructure.subRole } : {}),
            },
          ],
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        },
      }
    : componentStructure

export const connectComponentToControllers = (
  componentStructure: RawComponentStructure,
  controllerProps: { id: string; isPrimary?: boolean }[],
) => ({
  ..._.omit(componentStructure, ['role', 'config']),
  connections: {
    type: 'ConnectionList',
    items: [
      ..._.get(componentStructure, 'connections.items', []),
      ..._.map(controllerProps, ({ id, isPrimary }) => ({
        type: 'ConnectionItem',
        role: componentStructure.role,
        isPrimary: !!isPrimary,
        config: JSON.stringify(componentStructure.config),
        controllerId: id,
      })),
    ],
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
  },
})

export const enhanceConfigByRole = async (
  structure: RawComponentStructure,
  produceConfigMap: { [key: string]: (config) => Promise<ComponentConfig> },
): Promise<RawComponentStructure> => {
  const scanStructure = async (componentStructure) => {
    const produceConfig = produceConfigMap[componentStructure.role] || _.identity
    return {
      ...componentStructure,
      config: await produceConfig(componentStructure.config),
      ...(componentStructure.components && {
        components: await Promise.all(
          componentStructure.components.map((component) =>
            enhanceConfigByRole(component, produceConfigMap),
          ),
        ),
      }),
    }
  }

  return scanStructure(structure)
}

export const enhanceStructreWithSnapshot = (
  structure: RawComponentStructure,
  formSnapshot: FormSnapshot,
  overrides: { config: ComponentConfig } = { config: {} },
): RawComponentStructure => {
  const formStructure = _.merge({}, structure, {
    config: { ...formSnapshot.formComponent.config, ...overrides.config },
    layout: formSnapshot.formComponent.layout,
  })
  formStructure.components = formSnapshot.components.map((component) =>
    _.omit(component, 'componentRef'),
  )
  return formStructure
}

export const getFormControllerType = (structure: RawComponentStructure): ControllerType => {
  const plugins = structure.config.plugins
  return _.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })
    ? 'multiStepForm'
    : _.find(plugins, { id: FormPlugin.GET_SUBSCRIBERS })
    ? 'getSubscribers'
    : 'wixForms'
}

export const getComponentByRole = (
  structure: ComponentStructure,
  role: string,
): ComponentStructure | undefined => {
  const primaryConnection = getPrimaryConnectionFromStructure(structure)
  if (primaryConnection && primaryConnection.role === role) {
    return structure
  }
  if (!structure.components) {
    return
  }
  return _.first(
    structure.components.map((component) => getComponentByRole(component, role)).filter((c) => c),
  )
}

export const connectComponentToConnection = (
  component: ComponentStructure,
  { role, config, controllerId },
): ComponentStructure => ({
  ...component,
  connections: {
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role,
        config: JSON.stringify(config),
        isPrimary: true,
        controllerId,
      },
    ],
  },
})

export const limitComponentInContainer = (component: ComponentStructure, containerHeight: number) =>
  component &&
  _.merge({}, component, {
    layout: {
      y: Math.max(Math.min(component.layout.y, containerHeight - component.layout.height - 10), 0),
    },
  })

type LayoutUpdate = { layout: Partial<Layout>; id: number; role?: string }
const calcFormWidthDesktop = ({
  inputFields,
  submitButton,
  successMessage,
  titles,
  form,
  spacing,
  width,
}: {
  inputFields: LayoutUpdate[]
  titles: LayoutUpdate[]
  submitButton: LayoutUpdate
  successMessage: LayoutUpdate
  form: LayoutUpdate
  spacing: number
  width: number
}): LayoutUpdate[] => {
  if (!inputFields.length) {
    return []
  }
  const elements = [...titles, ...inputFields, submitButton, successMessage].filter(
    (element) => element,
  )

  const fieldsUpdates: LayoutUpdate[] = []
  const isFieldsSharingTheRow = (field: LayoutUpdate, index: number) =>
    index > 0 && field.layout.y === inputFields[index - 1].layout.y
  const rows = inputFields.reduce<LayoutUpdate[][]>((acc, curr, index) => {
    if (isFieldsSharingTheRow(curr, index)) {
      acc[acc.length - 1] = acc[acc.length - 1].concat(curr)
    } else {
      acc = acc.concat([[curr]])
    }
    return acc
  }, [])
  const isSubmitButtonSharingRow = _.get(rows, '[0][0].y') === submitButton.layout.y
  const updateFormKeepColumns = (rowsToReduce: LayoutUpdate[][]) =>
    rowsToReduce.reduce((prevY, row, idx) => {
      const firstElement = row[0]
      const buttonInRow = isSubmitButtonSharingRow && idx === 0
      const fieldsInRow = buttonInRow ? row.length + 1 : row.length
      const widthForFields = width - spacing * (fieldsInRow - 1)
      const elementWidth = buttonInRow
        ? widthForFields / (fieldsInRow - 0.75)
        : widthForFields / fieldsInRow

      const newY = prevY >= 0 ? prevY + spacing : firstElement.layout.y
      row.reduce((prevX, field) => {
        const newX = prevX >= 0 ? prevX + spacing + elementWidth : field.layout.x
        fieldsUpdates.push({
          id: field.id,
          layout: { width: elementWidth, x: newX, y: newY },
        })
        return newX
      }, -1)

      return newY + (firstElement.layout.height || 0)
    }, -1)

  titles.forEach((element) => {
    if (element) {
      fieldsUpdates.push({
        id: element.id,
        layout: { width },
      })
    }
  })

  const lastY = updateFormKeepColumns(rows)
  const lastElement = rows[rows.length - 1][0]
  const heightDiff = lastElement.layout.y + lastElement.layout.height - lastY
  const buttonNewY = submitButton.layout.y - heightDiff

  if (isSubmitButtonSharingRow) {
    const fieldInFirstRow = fieldsUpdates.find((field) => field.id === _.last(rows[0])?.id)
    fieldsUpdates.push({
      id: submitButton.id,
      layout: {
        width: (fieldInFirstRow.layout.width || 0) / 4,
        x: (fieldInFirstRow.layout.x || 0) + (fieldInFirstRow.layout.width || 0) + spacing,
        y: fieldInFirstRow.layout.y || 0,
      },
    })
  } else if (submitButton.layout.width === form.layout.width) {
    fieldsUpdates.push({
      id: submitButton.id,
      layout: { width, y: buttonNewY, x: lastElement.layout.x },
    })
  } else {
    const calcXDecrease = (elmX: number, elmWidth: number) => {
      const widthDiff = form.layout.width - width
      const leftSpace = elmX - lastElement.layout.x
      const sidesRadio = leftSpace / (form.layout.width - elmWidth)
      return widthDiff * sidesRadio
    }
    fieldsUpdates.push({
      id: submitButton.id,
      layout: {
        x: submitButton.layout.x - calcXDecrease(submitButton.layout.x, submitButton.layout.width),
        y: buttonNewY,
      },
    })
  }

  if (successMessage) {
    const messageNewY = successMessage.layout.y - heightDiff
    const textNewWidth = width * (successMessage.layout.width / form.layout.width)
    fieldsUpdates.push({
      id: successMessage.id,
      layout: { width: textNewWidth, y: messageNewY },
    })
  }

  const lastField = fieldsUpdates[fieldsUpdates.length - 1]
  const originalField = elements.find((element) => element.id === lastField.id)
  const height = (lastField.layout.y || 0) + (originalField.layout.height || 0) + spacing

  const formUpdate: LayoutUpdate = {
    id: form.id,
    layout: { width, height },
  }

  return [formUpdate, ...fieldsUpdates]
}

// this function will work only with naive form structure
const changeFormWidth = (
  formStructure: RawComponentStructure,
  width: number,
  spacing: number,
): RawComponentStructure => {
  const toLayoutUpdate = (component: RawComponentStructure, index: number): LayoutUpdate => ({
    layout: component.layout,
    role: component.role,
    id: index,
  })
  const formComponents = formStructure.components.map(toLayoutUpdate)
  const submitButton = _.find(formComponents, { role: ROLE_SUBMIT_BUTTON })
  const successMessage = _.find(formComponents, { role: ROLE_MESSAGE })
  const inputFields = formComponents.filter((component) => isInputField(component.role))
  const titles = formComponents.filter(
    (component) => component.role === ROLE_TITLE || component.role === ROLE_SUBTITLE,
  )

  const [formUpdate, ...componentsUpdated] = calcFormWidthDesktop({
    inputFields: _.sortBy(inputFields, [(c) => c.layout.y, (c) => c.layout.x]),
    submitButton,
    successMessage,
    titles,
    form: toLayoutUpdate(formStructure, -1),
    width,
    spacing,
  })

  const components = formStructure.components.map((comp, idx) => ({
    ...comp,
    layout: { ...comp.layout, ..._.find(componentsUpdated, (update) => update.id === idx).layout },
  }))

  return {
    ...formStructure,
    layout: { ...formStructure.layout, ...formUpdate.layout },
    components,
  }
}
