import _ from 'lodash'
import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import {
  duplicateRules,
  extractAffectedFieldIds,
  NOTIFICATION_EVENTS,
  Rule,
} from '@wix/forms-common'
import { ROLES_FIELDS_WITH_OPTIONS, ROLE_FORM, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import {
  RulesData,
  RULES_UPDATE_STATUS,
} from '../../../panels/form-settings-panel/components/rules/rules-types'
import { changeFieldIdInActions } from '../utils'
import { absorbException } from '../decorators'

export default class RulesApi {
  private biLogger: any
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private experiments: Experiments

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  public async loadRules(controllerRef: ComponentRef): Promise<RulesData> {
    try {
      const rules = await this.coreApi.get<Rule[]>(controllerRef, 'rules')

      return {
        rules: rules || [],
        rulesUpdateStatus: RULES_UPDATE_STATUS.SUCCESS,
      }
    } catch (err) {
      return {
        rules: null,
        rulesUpdateStatus: RULES_UPDATE_STATUS.FAILED,
      }
    }
  }

  public hasRuleWithField(fieldId: string, rules: Rule[]): boolean {
    return !!_.find(rules, (rule) => this.isRuleWithField(fieldId, rule))
  }

  public isRuleWithField(fieldId: string, rule: Rule): boolean {
    const { conditions, actions } = extractAffectedFieldIds(rule)
    return _.includes([...conditions, ...actions], fieldId)
  }

  public ruleFieldOptionNotInEditedField(field: FormField, rules: Rule[]) {
    const ruleWithMissingOption = _.find(rules, (rule) => {
      const [fieldId, filter] = _.head(_.entries(rule.conditions))
      const [op, values] = _.head(_.entries<any>(filter))

      const fieldInConditions = fieldId === field.componentRef.id
      const isHasSomeOperation = fieldInConditions && op === '$hasSome'
      const missingOption =
        isHasSomeOperation &&
        _.difference(
          values,
          field.options.map((option) => option.value),
        ).length !== 0

      return missingOption
    })
    return ruleWithMissingOption
  }

  public async handleDataChanged(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
    previousData: ComponentStructure['data'],
  ) {
    if (!_.includes(ROLES_FIELDS_WITH_OPTIONS, _.get(componentConnection, 'role'))) {
      return
    }
    const controllerRef = _.get(componentConnection, 'controllerRef')

    if (!controllerRef) {
      return
    }

    const formComponent = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)

    if (!formComponent) {
      return
    }

    const {
      config: { plugins },
    } = formComponent.connection

    const rulesData = await this.loadRules(controllerRef)

    const field = await this.coreApi.fields.getField(componentRef)

    if (
      rulesData.rulesUpdateStatus === RULES_UPDATE_STATUS.SUCCESS &&
      _.size(rulesData.rules) > 0
    ) {
      if (this._isRuleOptionOnlyValueChanged({ field, rules: rulesData.rules, previousData })) {
        await this._updateRuleWithNewValue({
          field,
          rules: rulesData.rules,
          previousData,
          controllerRef,
        })
      } else if (this.ruleFieldOptionNotInEditedField(field, rulesData.rules)) {
        this.coreApi.popNotificationAction({
          componentRef: formComponent.ref,
          notificationTrigger: NOTIFICATION_EVENTS.RULES_AFFECTED_OPTION_DELETED,
          plugins,
        })
      }
    }
  }

  public updateRules(controllerRef: ComponentRef, newRules: Rule[]) {
    return newRules.length
      ? this.coreApi.set(controllerRef, 'rules', newRules)
      : this.coreApi.removeExternalId(controllerRef)
  }

  public async handleFieldDeletion(
    componentRef: ComponentRef,
    primaryConnection: ComponentConnection,
    formComponentRef: ComponentRef,
    formComponentConnection: ComponentConnection,
  ) {
    const { role, controllerRef } = primaryConnection
    const {
      config: { plugins },
    } = formComponentConnection
    const rulesData = await this.loadRules(controllerRef)
    if (rulesData.rulesUpdateStatus === RULES_UPDATE_STATUS.SUCCESS) {
      if (this.hasRuleWithField(componentRef.id, rulesData.rules)) {
        if (role === ROLE_SUBMIT_BUTTON) {
          const newRules = changeFieldIdInActions(
            componentRef.id,
            ROLE_SUBMIT_BUTTON,
            rulesData.rules,
          )
          await this.updateRules(controllerRef, newRules)
        }
        this.coreApi.popNotificationAction({
          componentRef: formComponentRef,
          notificationTrigger: NOTIFICATION_EVENTS.RULES_AFFECTED_FIELD_DELETED,
          plugins,
        })
      }
    }
  }

  @absorbException('rules-api')
  public async handleDuplicatedRules(
    controllerRef: ComponentRef,
    formRef: ComponentRef,
    originalFormRef?: ComponentRef,
  ) {
    const { rules: originalRules, rulesUpdateStatus } = await this.loadRules(controllerRef)
    if (!originalFormRef || rulesUpdateStatus === RULES_UPDATE_STATUS.FAILED) {
      return this.boundEditorSDK.components.data.update({
        componentRef: controllerRef,
        data: { externalId: undefined },
      })
    }

    const originalFieldIds = (
      await this.coreApi.fields.getFieldsSortByXY(originalFormRef, { allFieldsTypes: true })
    ).map((x) => x.componentRef.id)
    const newFieldIds = (
      await this.coreApi.fields.getFieldsSortByXY(formRef, { allFieldsTypes: true })
    ).map((x) => x.componentRef.id)

    const idMappings = _.zipObject(originalFieldIds, newFieldIds)
    const updatedRules = duplicateRules(originalRules, idMappings)

    return this.updateRules(controllerRef, updatedRules)
  }

  public async restoreSubmitButton(controllerRef: ComponentRef, newRef: ComponentRef) {
    const rulesData = await this.loadRules(controllerRef)
    if (rulesData.rulesUpdateStatus === RULES_UPDATE_STATUS.SUCCESS) {
      const newRules = changeFieldIdInActions(ROLE_SUBMIT_BUTTON, newRef.id, rulesData.rules)
      await this.updateRules(controllerRef, newRules)
    }
  }

  private async _updateRuleWithNewValue({
    field,
    rules,
    previousData,
    controllerRef,
  }: {
    field: FormField
    rules: Rule[]
    previousData: ComponentStructure['data']
    controllerRef: ComponentRef
  }) {
    const clonedRules = _.cloneDeep(rules)
    const {
      additionalData: { affectedRulesInfo },
    } = this._checkIfOnlyChoiceValueHasChangedInRules({ rules, previousData, field })

    const updatedRules = clonedRules.map((rule, index) => {
      const affectedRuleInfo = affectedRulesInfo.find((ruleInfo) => ruleInfo.ruleIndex === index)

      if (affectedRuleInfo) {
        const { valueToReplace, valueToReplaceIdx } = affectedRuleInfo
        const [, filter] = _.head(_.entries(rule.conditions))
        const [, values] = _.head(_.entries<any>(filter))
        const idxToReplace = _.indexOf(values, valueToReplace)

        values.splice(idxToReplace, 1, _.get(field.options, valueToReplaceIdx).value)
      }

      return rule
    })

    await this.updateRules(controllerRef, updatedRules)
  }

  private _checkIfOnlyChoiceValueHasChangedInRules({ rules, previousData, field }) {
    const affectedRulesInfo = []

    _.forEach(rules, (rule, ruleIdx) => {
      const [fieldId, filter] = _.head(_.entries(rule.conditions))
      const [op, values] = _.head(_.entries<any>(filter))
      const fieldInConditions = fieldId === field.componentRef.id
      const isHasSomeOperation = fieldInConditions && op === '$hasSome'

      _.forEach(field.options, (option, index) => {
        const previousDataOption = _.get(previousData, `options.${index}`)
        const labelKey = _.isNil((option as any).label) ? 'text' : 'label'

        if (
          _.includes(values, previousDataOption.value) &&
          option.value !== previousDataOption.value &&
          option[labelKey] === previousDataOption[labelKey] &&
          isHasSomeOperation
        ) {
          affectedRulesInfo.push({
            ruleIndex: ruleIdx,
            valueToReplace: previousDataOption.value,
            valueToReplaceIdx: index,
          })
        }
      })
    })

    return {
      isRuleValueChanged: affectedRulesInfo.length > 0,
      additionalData: { affectedRulesInfo },
    }
  }

  private _isRuleOptionOnlyValueChanged({
    field,
    rules,
    previousData,
  }: {
    field: FormField
    rules: Rule[]
    previousData: ComponentStructure['data']
  }) {
    if (field.options.length !== _.get(previousData, 'options', []).length) {
      return false
    }

    const { isRuleValueChanged } = this._checkIfOnlyChoiceValueHasChangedInRules({
      rules,
      previousData,
      field,
    })

    return isRuleValueChanged
  }
}
