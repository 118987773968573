import { undoable, withBi, absorbException } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import CoreApi from '../core-api'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_LIMIT_MESSAGE,
  FIELDS,
  AUTOFILL_MEMBER_EMAIL_ROLE,
} from '../../../constants/roles'
import { getExtraMessageText } from '../services/form-service'
import { LinkTypes, MediaTypes } from '../../../panels/submit-settings-panel/constants'
import { MissingField } from '../../../constants/field-types'
import {
  FormsFieldPreset,
  FormPlugin,
  SecondsToResetDefaults,
  SuccessActionTypes,
} from '@wix/forms-common'
import { innerText } from '../../../utils/utils'
import _ from 'lodash'
import {
  DEFAULT_EXTERNAL_LINK_OBJECT,
  DEFAULT_LINK_OBJECT,
  DEFAULT_UPLOAD_OBJECT,
  LinkPanelTypesToActionTypes,
  UploadStatuses,
  VISIBLE_LINK_PANEL_SECTIONS,
  VISIBLE_LINK_PANEL_SECTIONS_ADI,
} from './consts/links-settings'
import RemoteApi from '../../../panels/commons/remote-api'
import { PremiumRestriction } from '../../../constants/premium'
import {
  convertPluginsToFormsPlugins,
  findPlugin,
  getPlugins,
  isNativeForm,
  removePlugin,
  updatePlugin,
  getFormPlugins,
} from '../plugins/utils'
import { SettingsPanelProps } from '../../../panels/form-settings-panel/components/form-settings-panel'
import translations from '../../../utils/translations'
import { PAYMENT_STATUS } from '../../../panels/form-settings-panel/components/payment/constants'
import { TABS } from '../../../panels/form-settings-panel/constants'
import Experiments from '@wix/wix-experiments'
import {
  ALWAYS_DISPLAY_MESSAGE,
  MessageDisplayOption,
} from '../../../panels/form-settings-panel/components/submit-message/constants'
import { getSyncedFieldsCount, isFieldSyncable } from '../contact-sync/utils'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { OwnSettingsTabProps } from '../../../panels/form-settings-panel/components/settings/settings'
import { PanelFieldStatus } from '../../../panels/commons/constants/field-statuses'
import { getAutofillMemberEmailConnection, getCountableFields } from '../fields/utils'
import { RULES_UPDATE_STATUS } from '../../../panels/form-settings-panel/components/rules/rules-types'
import { OwnRulesTabProps } from '../../../panels/form-settings-panel/components/rules/rules'
import { OwnPaymentTabProps } from '../../../panels/form-settings-panel/components/payment/payment'

import { PAYMENT_OPTIONS } from '../../../panels/payment-wizard-panel/constants'
import { getFieldsWithNonPrimaryRole } from '../../../panels/form-settings-panel/components/settings/autofill-form-info/utils'
import { ErrorName } from '../../forms-editor-app/errors'
import { Form as DomainForm } from '@wix/ambassador-forms-v2-form/types'
import EmailsNotificationsApi from './emails-notifications/api'
import SubmissionLimitApi from './submission-limit/api'
import { LabelType } from '@wix/ambassador-contacts-v4-label/types'

export interface InitialSettingsPanelData {
  message?: string
  links?: any
  email?: string
  secondEmail?: string
  missingFields?: MissingField[]
  otherFormsNames?: string[]
  isCollectionExists?: boolean
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  productName?: string
  productPrice?: string
}

export interface InitialSubmitPanelData {
  message?: string
  links?: any
  missingFields?: MissingField[]
  isRegistrationForm?: boolean
  restrictions?: PremiumRestriction
  submitMessageOptionsSupported: boolean
  isResponsive: boolean
}

export const SETTINGS_API_NAME = 'settings'

const filterPredefinedLabels = (labels: DomainContactLabel[]) =>
  _.filter(
    labels,
    (label) =>
      label.type === LabelType.USER_DEFINED ||
      _.includes(['contacts.contacted-me', 'contacts.customers'], label.key),
  )

const apiPath = (funcName) => `${SETTINGS_API_NAME}.${funcName}`

export default class SettingsApi {
  private biLogger: BILogger
  private experiments: Experiments
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  public notifications: EmailsNotificationsApi
  public submissionLimit: SubmissionLimitApi

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.experiments = experiments
    this.submissionLimit = new SubmissionLimitApi(boundEditorSDK, coreApi)
    this.notifications = new EmailsNotificationsApi(boundEditorSDK, coreApi, remoteApi, {
      biLogger,
      experiments,
    })
  }

  public async loadInitialSubmitPanelData(
    componentRef: ComponentRef,
  ): Promise<InitialSubmitPanelData> {
    const formComponentRef = await this.coreApi.findComponentByRole(componentRef, ROLE_FORM)
    const formComponentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const { restrictions } = await this.coreApi.premium.getRestrictions()
    return Promise.all([
      this.getMessage(formComponentRef),
      this.getMessage(formComponentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(formComponentRef, formComponentConnection),
      this.getCrucialElements(formComponentRef, formComponentConnection),
      this.coreApi.isRegistrationForm(formComponentRef),
      this.coreApi.isMultiStepForm(formComponentRef),
      this.coreApi.getButtonLabel(componentRef),
      this.determinePaymentStatus(formComponentRef, restrictions),
      this.coreApi.isResponsive(),
      this.coreApi.getIsKillingAscend(),
    ]).then(
      ([
        successMessage,
        downloadMessage,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        buttonLabel,
        { paymentStatus },
        isResponsive,
        isKillingAscend,
      ]) => ({
        successMessage: successMessage.text,
        downloadMessage: downloadMessage.text,
        messagePosition: successMessage.position || downloadMessage.position,
        links,
        missingFields,
        isRegistrationForm,
        isMultiStepForm,
        restrictions,
        buttonLabel,
        formComponentRef,
        formComponentConnection,
        submitComponentRef: componentRef,
        paymentStatus,
        submitMessageOptionsSupported: !isMultiStepForm,
        isResponsive,
        isKillingAscend,
      }),
    )
  }

  public async loadPaymentTabData(
    formComponentRef: ComponentRef,
    componentConnection: ComponentConnection,
    {
      restrictions,
      fieldsOnStage,
    }: { restrictions: PremiumRestriction; fieldsOnStage: FormField[] },
  ): Promise<Partial<OwnPaymentTabProps>> {
    try {
      const [{ paymentStatus, paymentStatusChanged }, currency] = await Promise.all([
        this.determinePaymentStatus(formComponentRef, restrictions),
        this.boundEditorSDK.info.getCurrency(),
      ])

      const fetchSingleItemPayload = (): Partial<OwnPaymentTabProps> => {
        const plugins = getPlugins(componentConnection)
        const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
        const items = _.get(paymentPlugin, 'payload.items')
        const products = _.map(items, (item, id) => ({ id, ...item }))

        return {
          productId: _.get(products, '[0].id'),
          productName: _.get(products, '[0].name'),
          productPrice: _.get(products, '[0].price'),
        }
      }

      const fetchItemsListPayload = (): Partial<OwnPaymentTabProps> => {
        const itemsListField = _.find(
          fieldsOnStage,
          (field) => field.role === FIELDS.ROLE_FIELD_ITEMS_LIST,
        )

        if (itemsListField && itemsListField.paymentItemsMapping) {
          const itemMapping = (option: RadioOption) =>
            itemsListField.paymentItemsMapping[option.value]
          const items: Product[] = _.map(itemsListField.options, (option: RadioOption) => ({
            id: option.value,
            name: itemMapping(option).label,
            price: itemMapping(option).price,
          }))

          return { items, paymentComponentRef: itemsListField.componentRef }
        }

        return {}
      }

      const fetchCustomItemPayload = (): Partial<OwnPaymentTabProps> => {
        const plugins = getPlugins(componentConnection)
        const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
        const items = _.get(paymentPlugin, 'payload.items')
        const products = _.map(items, (item, id) => ({ id, ...item }))

        const customAmountField = _.find(
          fieldsOnStage,
          (field) => field.role === FIELDS.ROLE_FIELD_CUSTOM_AMOUNT,
        )

        const customItem: Product = {
          id: _.get(products, '[0].id'),
          name: _.get(products, '[0].name'),
          price: _.get(products, '[0].price'),
          min: _.get(products, '[0].min'),
          max: _.get(products, '[0].max'),
        }

        return { customItem, paymentComponentRef: _.get(customAmountField, 'componentRef') }
      }

      const selectedPaymentOption = _.get(
        componentConnection,
        'config.selectedPaymentOption',
        PAYMENT_OPTIONS.SINGLE,
      )

      let payload: Partial<OwnPaymentTabProps> = {}

      switch (selectedPaymentOption) {
        case PAYMENT_OPTIONS.LIST:
          payload = fetchItemsListPayload()
          break
        case PAYMENT_OPTIONS.CUSTOM:
          payload = fetchCustomItemPayload()
          break
        default:
          payload = fetchSingleItemPayload()
      }

      return {
        currency,
        selectedPaymentOption,
        paymentStatus: paymentStatus || PAYMENT_STATUS.GET_STARTED,
        paymentStatusChanged: !!paymentStatusChanged,
        paymentTabDataLoaded: PanelFieldStatus.DONE,
        ...payload,
      }
    } catch (err) {
      return { paymentTabDataLoaded: PanelFieldStatus.FAILED }
    }
  }

  private _disconnectAllEmailFieldsWithAutofillRole = async ({
    controllerRef,
    autofillEmailFields,
    formComponentRef,
  }: {
    controllerRef: ComponentRef
    autofillEmailFields: FormField[]
    formComponentRef: ComponentRef
  }) =>
    Promise.all(
      _.map(autofillEmailFields, (field) =>
        this.coreApi.disconnectComponentAndSetFormConfig({
          componentConnection: {
            role: AUTOFILL_MEMBER_EMAIL_ROLE,
            controllerRef,
          },
          componentRef: field.componentRef,
          formComponentRef,
          formConfig: { isAutofillEmailEnabled: false },
        }),
      ),
    )

  private _getAutofillMemberEmailTabInitialState = async ({
    fieldsOnStage,
    config,
    isMembersAreaInstalled,
    controllerRef,
    formComponentRef,
  }: {
    fieldsOnStage: FormField[]
    config: ComponentConfig
    isMembersAreaInstalled: boolean
    controllerRef: ComponentRef
    formComponentRef: ComponentRef
  }) => {
    const autofillEmailFields = getFieldsWithNonPrimaryRole(
      fieldsOnStage,
      AUTOFILL_MEMBER_EMAIL_ROLE,
    )

    if (!isMembersAreaInstalled) {
      autofillEmailFields.length > 0 &&
        (await this._disconnectAllEmailFieldsWithAutofillRole({
          autofillEmailFields,
          formComponentRef,
          controllerRef,
        }))

      return {
        autoFilledEmailRef: undefined,
        isAutofillEmailEnabled: false,
        isEmailFieldEditable: true,
      }
    } else {
      const firstAutofillEmailField = _.first(autofillEmailFields)
      const autofillConnection =
        firstAutofillEmailField &&
        getAutofillMemberEmailConnection(firstAutofillEmailField.nonPrimaryConnections)
      const autoFilledEmailRef = firstAutofillEmailField?.componentRef
      const isEmailFieldEditable = _.get(autofillConnection, 'config.isEditable', true)
      const isAutofillEmailEnabled = _.isUndefined(autoFilledEmailRef)
        ? !!config?.isAutofillEmailEnabled
        : !!autoFilledEmailRef

      return { isAutofillEmailEnabled, isEmailFieldEditable, autoFilledEmailRef }
    }
  }

  public async getForm(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ): Promise<DomainForm> {
    const formId = await this.coreApi.getFormId(componentRef, componentConnection)
    const form = await this.remoteApi.formServiceClient.getForm(formId).catch(async (e) => {
      if (e.response.status === 404) {
        await this.coreApi.editDraft(componentRef)
        return this.remoteApi.formServiceClient.getForm(formId)
      }
    })
    return form
  }

  public async loadSettingsTabData(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
    fieldsOnStage: FormField[],
    isMembersAreaInstalled,
  ): Promise<Partial<OwnSettingsTabProps>> {
    try {
      const [commonStyles, form] = await Promise.all([
        this.coreApi.style.getFieldsCommonStylesGlobalDesign(componentRef),
        this.getForm(componentRef, componentConnection),
      ])

      const { config, controllerRef } = componentConnection
      const [emailNotificationTabState, autofillMemberEmailTabState] = await Promise.all([
        this.notifications.getEmailsAndSiteUsers(componentRef, componentConnection, form),
        this._getAutofillMemberEmailTabInitialState({
          fieldsOnStage,
          config,
          controllerRef,
          formComponentRef: componentRef,
          isMembersAreaInstalled,
        }),
      ])
      const formInfoData = {
        formName: form.name,
        formPublicId: form.id,
        lastValidFormName: form.name,
        formRevision: form.revision,
        otherFormsNames: await this.remoteApi.formServiceClient.getOtherFormNames({
          publicId: form.id,
        }),
      }

      return {
        commonStyles,
        ...emailNotificationTabState,
        ...formInfoData,
        settingsTabDataLoaded: PanelFieldStatus.DONE,
        ...autofillMemberEmailTabState,
      }
    } catch (err) {
      const httpStatus = _.get(err, 'httpStatus')
      return {
        settingsTabDataLoaded: PanelFieldStatus.FAILED,
        errorType: httpStatus === 403 ? ErrorName.Permissions : ErrorName.FailedToCallApi,
      }
    }
  }

  public async loadInitialSettingsPanelData({
    componentRef,
    componentConnection,
    displayedTab,
    initTabExtraData,
  }: {
    componentRef: ComponentRef
    componentConnection: ComponentConnection
    displayedTab: any
    initTabExtraData?: any
  }): Promise<Partial<SettingsPanelProps>> {
    const { restrictions, currentPlan } = await this.coreApi.premium.getRestrictions()

    const plugins = getPlugins(componentConnection)
    const isMultiStepForm = !!findPlugin(plugins, FormPlugin.MULTI_STEP_FORM)

    return Promise.all([
      this.getMessage(componentRef),
      this.getMessage(componentRef, ROLE_DOWNLOAD_MESSAGE),
      this.getSubmitOptionsData(componentRef, componentConnection),
      this.getCrucialElements(componentRef, componentConnection),
      this.getSubmitComponentRef(componentRef),
      this.coreApi.isCollectionExists(componentRef, componentConnection),
      this.coreApi.isWixChatInstalled(),
      this.boundEditorSDK.environment.getLocale(),
      this.coreApi.fields.getFieldsSortByXY(componentRef),
      this.coreApi.fetchAppConfig({ formComponentRef: componentRef }),
      this.getLabels({ filterUserLabels: false }),
      isMultiStepForm ? this.coreApi.steps.getSteps(componentRef) : Promise.resolve([]),
      isMultiStepForm
        ? this.coreApi.steps.getCurrentStateIndex(componentRef)
        : Promise.resolve(null),
      isMultiStepForm ? this.coreApi.steps.getLastStepIndex(componentRef) : Promise.resolve(null),
      this.coreApi.getSessionDeletedFields(),
      this.coreApi.isMembersAreaInstalled(),
      this.submissionLimit.loadSubmissionLimitTabData(componentRef, componentConnection),
    ]).then((payload: any) => {
      // TODO: Fix issue with types
      const [
        successMessage,
        downloadMessage,
        links,
        missingFields,
        submitComponentRef,
        isCollectionExists,
        isWixChatInstalled,
        locale,
        fieldsOnStage,
        appConfig,
        labels,
        stepsData,
        currentStepIndex,
        lastStepIndex,
        sessionDeletedFields,
        isMembersAreaInstalled,
        limitData,
      ] = payload

      const formPlugins = getFormPlugins(componentConnection)

      const showPaymentTabInFormBuilderPlugin =
        isNativeForm(plugins) || !!findPlugin(plugins, FormPlugin.PAYMENT_FORM)

      const showPaymentTabInGetSubscribersPlugin =
        !this.coreApi.isResponsive() && !!findPlugin(plugins, FormPlugin.GET_SUBSCRIBERS)

      const showPaymentTabInRegistrationFormPlugin =
        this.experiments.enabled('specs.cx.FormBuilderShowPaymentTabInRegistrationForm') &&
        !!findPlugin(plugins, FormPlugin.REGISTRATION_FORM)

      const showPaymentTabInPlugin =
        showPaymentTabInFormBuilderPlugin ||
        showPaymentTabInGetSubscribersPlugin ||
        showPaymentTabInRegistrationFormPlugin

      const preset = _.get(componentConnection, 'config.preset')
      const formPresetType = _.get(componentConnection, 'config.presetType')

      const countableFields = getCountableFields(fieldsOnStage)

      const syncableFields = countableFields
        .filter((field) => isFieldSyncable(formPlugins, field))
        .map(({ crmType, customFieldKey }) => ({
          crmType,
          customFieldKey,
        }))

      const { secondsToResetForm } = componentConnection.config

      const settingsPanelProps: Partial<SettingsPanelProps> = {
        displayedTab,
        initTabExtraData,
        appConfig,
        isCollectionExists,
        preset,
        formPresetType,
        plugins: convertPluginsToFormsPlugins(plugins),
        formLabelKey: componentConnection.config.formLabelKey,
        selectedLabels: componentConnection.config.labelKeys || [],
        formName: componentConnection.config.formName,
        lastValidFormName: componentConnection.config.formName,
        successActionType:
          componentConnection.config.successActionType || SuccessActionTypes.SHOW_MESSAGE,
        secondsToResetForm:
          componentConnection.config.secondsToResetForm || SecondsToResetDefaults.MIN,
        messageDisplayOption: _.eq(secondsToResetForm, ALWAYS_DISPLAY_MESSAGE)
          ? MessageDisplayOption.ALWAYS
          : MessageDisplayOption.CUSTOM_TIME,
        links,
        missingFields,
        successMessage: _.get(successMessage, 'text') || appConfig.content.onSubmitMessage,
        downloadMessage:
          _.get(downloadMessage, 'text') || translations.t('settings.successMessage.download'),
        restrictions,
        submitComponentRef,
        messagePosition: successMessage.position || downloadMessage.position,
        isWixChatInstalled,
        selectedTab: displayedTab || TABS.MAIN,
        showPaymentTab: showPaymentTabInPlugin,
        locale,
        fieldsOnStage,
        syncedFieldsCount: getSyncedFieldsCount(syncableFields),
        currentPlan,
        submitMessageOptionsSupported: !findPlugin(plugins, FormPlugin.MULTI_STEP_FORM),
        labels: labels && filterPredefinedLabels(labels),
        forbiddenLabels: _.reduce(
          labels,
          (filtered, label) => {
            if (label.type === LabelType.SYSTEM) {
              filtered.push(label.name)
            }

            return filtered
          },
          [],
        ),
        doubleOptIn: _.get(componentConnection, 'config.doubleOptIn'),
        hasSubscriberField: _.some(
          fieldsOnStage,
          (field: FormField) => field.fieldType === FormsFieldPreset.GENERAL_SUBSCRIBE,
        ),
        stepsData,
        currentStepIndex,
        sessionDeletedFields,
        lastStepIndex,
        isMembersAreaInstalled,
        ...limitData,
      }

      return settingsPanelProps
    })
  }

  public async createCollectionAndOpenPopup(
    formComponentRef: ComponentRef,
    msid: string,
    extraBiData = {},
  ) {
    const collectionId = await this.coreApi.createManualCollection(formComponentRef, extraBiData)
    this.coreApi.managePanels.openPublishSitePopup(formComponentRef)
    return collectionId
  }

  @undoable()
  public async updateDoubleOptInToggle(componentRef: ComponentRef, value) {
    return this.coreApi.setComponentConnection(componentRef, { doubleOptIn: value })
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setLabels(componentRef: ComponentRef, labelKeys: string[], _biData = {}) {
    const configUpdates = { labelKeys }

    return this.coreApi.setComponentConnection(componentRef, configUpdates, false)
  }

  @undoable()
  public setAsPaymentForm(
    componentRef: ComponentRef,
    {
      currency,
      product,
      selectedPaymentOption,
    }: { currency: string; product?: Product; selectedPaymentOption?: PAYMENT_OPTIONS },
  ) {
    return this.addPaymentFormData(componentRef, { currency, product, selectedPaymentOption })
  }

  public async addPaymentFormData(
    componentRef: ComponentRef,
    {
      currency,
      product,
      selectedPaymentOption,
    }: { currency: string; product?: Product; selectedPaymentOption?: PAYMENT_OPTIONS },
  ) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins: ComponentPlugin[] = _.get(componentConnection, 'config.plugins')

    let paymentPlugin: ComponentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const createProductData = () => ({
      [product.id]: {
        name: product.name,
        price: product.price,
        quantity: product.quantity || 1,
        min: product.min,
        max: product.max,
      },
    })

    const initPaymentPlugin = () => {
      paymentPlugin = {
        id: FormPlugin.PAYMENT_FORM,
        payload: {
          currency,
        },
      }

      if (product) {
        paymentPlugin.payload.items = createProductData()
      }
    }

    const updatePaymentPlugin = () => {
      paymentPlugin.payload.currency = currency

      if (product) {
        paymentPlugin.payload.items = createProductData()
      } else {
        delete paymentPlugin.payload.items
      }
    }

    if (paymentPlugin && paymentPlugin.payload) {
      updatePaymentPlugin()
    } else {
      const collectionId = await this.coreApi.getValidCollectionId({
        componentRef,
        collectionId: _.get(componentConnection, 'config.collectionId'),
      })

      if (collectionId) {
        await this.coreApi.collectionsApi.addPaymentField(collectionId)
      }

      initPaymentPlugin()
    }

    const updatedPlugins = updatePlugin(plugins, paymentPlugin)

    return this.coreApi.setComponentConnection(
      componentRef,
      {
        plugins: updatedPlugins,
        selectedPaymentOption: selectedPaymentOption || PAYMENT_OPTIONS.SINGLE,
      },
      false,
    )
  }

  public async removePlugin(componentRef: ComponentRef, pluginId: FormPlugin) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = getPlugins(componentConnection)

    return this.coreApi.setComponentConnection(
      componentRef,
      {
        plugins: removePlugin(plugins, pluginId),
      },
      false,
    )
  }

  @undoable()
  public async removePaymentForm(
    formComponentRef: ComponentRef,
    pluginId: FormPlugin,
    adiConfiguration?: { showFieldsTitles: boolean },
  ) {
    const componentConnection = await this.coreApi.getComponentConnection(formComponentRef)
    const plugins = getPlugins(componentConnection)
    const { controllerRef } = componentConnection
    const fields = await this.coreApi.findConnectedComponentsByRole(controllerRef, [
      FIELDS.ROLE_FIELD_ITEMS_LIST,
      FIELDS.ROLE_FIELD_CUSTOM_AMOUNT,
    ])

    _.each(fields, (field) => this.coreApi.removeComponentRef(field))

    await this.coreApi.setComponentConnection(
      formComponentRef,
      {
        plugins: removePlugin(plugins, pluginId),
        selectedPaymentOption: undefined,
      },
      false,
    )

    if (fields.length && this.coreApi.isADI()) {
      await this.coreApi.layout.updateFieldsLayoutADI(formComponentRef, {
        showTitles: adiConfiguration?.showFieldsTitles,
      })
    }
  }

  public async determinePaymentStatus(
    componentRef: ComponentRef,
    restrictions: PremiumRestriction,
  ): Promise<{ paymentStatus: PAYMENT_STATUS; paymentStatusChanged: boolean }> {
    const [hasConnectedPayment, componentConnection] = await Promise.all([
      this.coreApi.hasConnectedPayment(),
      this.coreApi.getComponentConnection(componentRef),
    ])

    const plugins = getPlugins(componentConnection)
    const oldPaymentStatus =
      (await _.get(componentConnection.config, 'paymentStatus')) || PAYMENT_STATUS.GET_STARTED
    const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)

    const getPaymentStatus = () => {
      if (!paymentPlugin || !paymentPlugin.payload) {
        return PAYMENT_STATUS.GET_STARTED
      }

      return hasConnectedPayment ? PAYMENT_STATUS.CONNECTED : PAYMENT_STATUS.MISSING_PAYMENT_METHOD
    }

    const paymentStatus = getPaymentStatus()
    const paymentStatusChanged =
      paymentStatus !== oldPaymentStatus &&
      oldPaymentStatus !== PAYMENT_STATUS.MISSING_PAYMENT_METHOD

    await this.coreApi.setComponentConnection(componentRef, { paymentStatus })
    return { paymentStatus, paymentStatusChanged }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async setSuccessActionTypeADI(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    showTitles,
    newSuccessMessage = '',
    _biData = {},
  ) {
    await this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage: newSuccessMessage,
    })
    await this.coreApi.layout.updateFieldsLayoutADI(connectToRef, { showTitles })
  }

  private async _handleActionTypeChange(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    oldSuccessActionType,
    newMessage: string,
  ) {
    const isMultistep = await this.coreApi.isMultiStepForm(formComponentRef)

    switch (oldSuccessActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        await this.removeMessage(formComponentRef)
        if (isMultistep && successActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        await this.removeMessage(formComponentRef, { role: ROLE_DOWNLOAD_MESSAGE })
        if (isMultistep && successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.removeThankYouStep(formComponentRef)
        }
        break
    }
    switch (successActionType) {
      case SuccessActionTypes.SHOW_MESSAGE:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.DOWNLOAD_DOCUMENT) {
          await this.coreApi.steps.restoreThankYouStep(formComponentRef, newMessage)
        } else {
          await this.coreApi.fields.restoreHiddenMessage(formComponentRef, newMessage)
        }
        break
      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        if (isMultistep && oldSuccessActionType !== SuccessActionTypes.SHOW_MESSAGE) {
          await this.coreApi.steps.restoreThankYouStep(
            formComponentRef,
            newMessage,
            ROLE_DOWNLOAD_MESSAGE,
          )
        } else {
          await this.coreApi.fields.restoreDownloadDocumentMessage(formComponentRef, newMessage)
        }
        break
    }
  }

  private async _setSuccessActionType(
    formComponentRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {},
  ) {
    const {
      config: { successActionType: oldSuccessActionType },
    } = await this.coreApi.getComponentConnection(formComponentRef)

    await this.coreApi.setComponentConnection(
      formComponentRef,
      { successActionType, successLinkValue },
      false,
    )

    await this._handleActionTypeChange(
      formComponentRef,
      successActionType,
      oldSuccessActionType,
      newMessage,
    )
  }

  @undoable()
  public async setSuccessActionType(
    connectToRef: ComponentRef,
    successActionType: SuccessActionTypes,
    successLinkValue,
    { newMessage }: { newMessage?: string } = {},
  ) {
    return this._setSuccessActionType(connectToRef, successActionType, successLinkValue, {
      newMessage,
    })
  }

  public async onCloseSettingsPanel(formComponentRef: ComponentRef) {
    this.submissionLimit.removeLimitComponentWhenNotActive(formComponentRef)
  }

  public async removeMessage(
    componentRef: ComponentRef,
    {
      role = ROLE_MESSAGE,
      existingMessageRef,
    }: { role?: string; existingMessageRef?: ComponentRef } = {},
  ) {
    const get = async () => {
      const messageRef =
        existingMessageRef || (await this.coreApi.findComponentByRole(componentRef, role))
      const messageLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: messageRef,
      })
      return { messageRef, messageLayout }
    }
    const { messageRef, messageLayout } = await get()
    if (!messageLayout) {
      return
    }
    return this.coreApi.removeComponentRef(messageRef)
  }

  @undoable()
  public async handleSuccessLinkPanel(componentRef: ComponentRef, isADI = false) {
    const {
      config: { successLinkValue, successActionType },
    } = await this.coreApi.getComponentConnection(componentRef)

    let linkObject
    try {
      linkObject = await this.boundEditorSDK.editor.openLinkPanel({
        value: successLinkValue,
        visibleSections: isADI ? VISIBLE_LINK_PANEL_SECTIONS_ADI : VISIBLE_LINK_PANEL_SECTIONS,
      } as any)
    } catch (e) {
      return {
        chosenLinkType: null,
        successLinkText: null,
        linkObject: null,
      }
    }

    const successLinkText = await this.boundEditorSDK.editor.utils.getLinkAsString({
      link: linkObject,
    })

    const chosenLinkType =
      linkObject && linkObject.type
        ? LinkPanelTypesToActionTypes[linkObject.type]
        : successActionType
    await this.setSuccessActionType(componentRef, chosenLinkType, linkObject)

    const successLinkType = await this._getLinkType(linkObject)
    const successLinkSubType = this._getLinkSubType(successLinkText, successLinkType, linkObject)

    return {
      chosenLinkType,
      successLinkText,
      linkObject,
      successLinkType,
      successLinkSubType,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.CLICK_UPLOAD_BUTTON })
  public async updateDownloadSection(componentRef: ComponentRef, _biData) {
    const {
      config: { successLinkValue },
    } = await this.coreApi.getComponentConnection(componentRef)

    try {
      const uploadedObject = (await this.boundEditorSDK.editor.openMediaPanel({
        mediaType: MediaTypes.DOCUMENT as any,
        isMultiSelect: false,
      })) as DocumentMediaResult[]

      if (uploadedObject) {
        successLinkValue.docId = _.head(uploadedObject).fileUrl
        successLinkValue.name = _.head(uploadedObject).title
        successLinkValue.status = UploadStatuses.UPLOAD_SUCCESS
      }
    } catch (error) {
      successLinkValue.status = UploadStatuses.UPLOAD_FAILED
    }
    await this.coreApi.setComponentConnection(
      componentRef,
      {
        successLinkValue,
      },
      false,
    )
    return successLinkValue
  }

  public async getSubmitOptionsData(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ) {
    const {
      config: { successActionType, successLinkValue },
    } = componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const getText = (linkObj) => this.boundEditorSDK.editor.utils.getLinkAsString({ link: linkObj })

    const links = {
      [SuccessActionTypes.LINK]: {
        text: await getText(DEFAULT_LINK_OBJECT),
        object: DEFAULT_LINK_OBJECT,
      },
      [SuccessActionTypes.EXTERNAL_LINK]: {
        text: await getText(DEFAULT_EXTERNAL_LINK_OBJECT),
        object: DEFAULT_EXTERNAL_LINK_OBJECT,
      },
      [SuccessActionTypes.DOWNLOAD_DOCUMENT]: {
        object: DEFAULT_UPLOAD_OBJECT,
      },
    }

    if (successActionType !== SuccessActionTypes.SHOW_MESSAGE) {
      links[successActionType] = {
        text: await getText(successLinkValue),
        object: successLinkValue,
      }
    }
    return links
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED,
    endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED,
  })
  public async updateMessage(
    componentRef: ComponentRef,
    { newMessage, role = ROLE_MESSAGE },
    _biData = {},
  ) {
    if (role === ROLE_LIMIT_MESSAGE) {
      this.submissionLimit.setCachedLimitMessage(componentRef.id, newMessage)
    }

    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    const data = await this.boundEditorSDK.components.data.get({ componentRef: messageRef })
    return this.boundEditorSDK.components.data.update({
      componentRef: messageRef,
      data: getExtraMessageText({ data, newMessage }),
    })
  }

  public async getSubmitButtonLabel(componentRef: ComponentRef) {
    const submitButtonRef = await this.getSubmitComponentRef(componentRef)
    return this.coreApi.getButtonLabel(submitButtonRef)
  }

  public getSubmitComponentRef(componentRef: ComponentRef): Promise<ComponentRef> {
    return this.coreApi.findComponentByRole(componentRef, ROLE_SUBMIT_BUTTON)
  }

  @undoable()
  public async updateSubmitButtonLabel(componentRef: ComponentRef, newLabel: string, biData) {
    const submitButtonRef = await this.getSubmitComponentRef(componentRef)
    return this.coreApi.updateButtonLabel(submitButtonRef, newLabel, { startBi: biData })
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.SECONDS_TO_RESET_UPDATED })
  public setComponentConnectionResetUpdated(
    connectToRef: ComponentRef,
    connectionConfig,
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getLabels({ filterUserLabels = true } = {}) {
    return this.remoteApi
      .getLabels()
      .then((labels) => (filterUserLabels ? filterPredefinedLabels(labels) : labels))
      .catch(() => null)
  }

  public async getMessage(
    componentRef: ComponentRef,
    role: string = ROLE_MESSAGE,
  ): Promise<Message> {
    const messageRef = await this.coreApi.findComponentByRole(componentRef, role)
    if (!messageRef) {
      return { text: '' }
    }
    const [{ data, layout }] = await this.boundEditorSDK.components.get({
      componentRefs: messageRef,
      properties: ['data', 'layout'],
    })
    const { x, y } = layout
    return { text: _.unescape(innerText(data.text)), position: { x, y } }
  }

  @absorbException('settings-panel')
  public async getEmailsAndSiteUsers(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ) {
    return this.notifications.getEmailsAndSiteUsers(componentRef, componentConnection)
  }

  public async getCrucialElements(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<MissingField[]> {
    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))

    const { controllerRef, config } = connection

    const plugins = _.get(config, 'plugins')
    const successActionType = _.get(config, 'successActionType')

    const pluginApi = this.coreApi.plugins.withPlugins(plugins)

    if (pluginApi) {
      // TODO: Thing about different solution
      const funcName = 'getCrucialElements'

      if (pluginApi.supportApi(apiPath(funcName))) {
        return pluginApi.callApi(apiPath(funcName), componentRef, connection)
      }
    }

    // TODO: Merge this with above using the plugin system solution

    let isPreviousButtonMissingPromise = Promise.resolve(null)
    let isNextButtonMissingPromise = Promise.resolve(null)
    let isLimitMessageMissingPromise = Promise.resolve(null)

    if (_.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })) {
      isPreviousButtonMissingPromise = this.coreApi.steps.isPreviousButtonMissing(componentRef)
      isNextButtonMissingPromise = this.coreApi.steps.isNextButtonMissing(componentRef)
    }

    if (findPlugin(plugins, FormPlugin.LIMIT_FORM_SUBMISSONS)) {
      isLimitMessageMissingPromise = this.coreApi.isFieldMissingByRole(
        componentRef,
        ROLE_LIMIT_MESSAGE,
        TABS.SETTINGS,
      )
    }

    const isMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.SHOW_MESSAGE
        ? this.coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE, TABS.SUBMIT_MESSAGE)
        : Promise.resolve(null)

    const isDownloadMessageFieldMissingPromise =
      successActionType === SuccessActionTypes.DOWNLOAD_DOCUMENT
        ? this.coreApi.isFieldMissingByRole(
            componentRef,
            ROLE_DOWNLOAD_MESSAGE,
            TABS.SUBMIT_MESSAGE,
          )
        : Promise.resolve(null)

    const missingFields = await Promise.all([
      isMessageFieldMissingPromise,
      isDownloadMessageFieldMissingPromise,
      this.coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON),
      this.coreApi.isEmailFieldMissing(controllerRef),
      isPreviousButtonMissingPromise,
      isNextButtonMissingPromise,
      isLimitMessageMissingPromise,
    ])

    return _.filter(missingFields)
  }

  public async getOtherFormsNames(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
  ): Promise<string[]> {
    const { controllerRef } =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const controllers = _.map(
      await this.boundEditorSDK.controllers.listAllControllers(),
      (controller) => controller.controllerRef,
    )
    return Promise.all(
      _.map(_.pullAllBy(controllers, [controllerRef], 'id'), async (formControllerRef) => {
        const form = await this.coreApi.findConnectedComponent(formControllerRef, ROLE_FORM)
        if (!form) {
          return ''
        }
        const {
          config: { formName },
        } = form.connection
        return formName
      }),
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async updateFormName(
    connectToRef: ComponentRef,
    {
      formName,
      formPublicId,
      formRevision,
    }: { formName: string; formPublicId: string; formRevision: number },
    contactsLabels: DomainContactLabel[],
    _biData = {},
  ) {
    await this.coreApi.setComponentConnection(connectToRef, { formName })
    const {
      config: { formLabelKey, labelKeys, collectionId },
    } = await this.coreApi.getComponentConnection(connectToRef)
    const updateActions = [
      Promise.resolve(null),
      Promise.resolve(null),
      this.remoteApi.formServiceClient.updateFormName({
        publicId: formPublicId,
        name: formName,
        revision: formRevision,
      }),
    ]

    if (collectionId) {
      const validCollectionId = await this.coreApi.getValidCollectionId({
        componentRef: connectToRef,
        collectionId,
      })
      updateActions[0] = this.coreApi.collectionsApi.updateCollectionName(
        validCollectionId,
        formName,
      )
    }

    const shouldChangeTag = !formLabelKey || _.includes(labelKeys, formLabelKey)
    const shouldCreateTagInsteadOfUpdate =
      !formLabelKey || !_.find(contactsLabels, { key: formLabelKey })

    if (shouldChangeTag) {
      if (shouldCreateTagInsteadOfUpdate) {
        updateActions[1] = this.remoteApi.createTag(formName).catch(() => ({ key: '' }))
      } else {
        updateActions[1] = this.remoteApi.updateTag(formLabelKey, formName)
      }
    }

    if (shouldChangeTag && shouldCreateTagInsteadOfUpdate) {
      const [, { key: newFormLabelKey }] = await Promise.all(updateActions)

      const newLabelKeys = _.filter(
        [...(labelKeys || []), newFormLabelKey],
        (labelKey) => labelKey !== formLabelKey,
      )

      await this.coreApi.setComponentConnection(connectToRef, {
        formLabelKey: newFormLabelKey,
        labelKeys: newLabelKeys,
      })
    } else {
      await Promise.all(updateActions)
    }
  }

  private async _getLinkType(linkObject) {
    if (!_.get(linkObject, 'pageId')) {
      if (_.get(linkObject, 'url')) {
        return LinkTypes.EXTERNAL_LINK
      }
      return LinkTypes.NONE
    }

    const linkedPageRef: ComponentRef = { type: 'DESKTOP', id: linkObject.pageId.substring(1) }
    const linkData = await this.boundEditorSDK.components.data.get({
      componentRef: linkedPageRef,
    })
    return _.get(linkData, 'isPopup') ? LinkTypes.LIGHTBOX : LinkTypes.PAGE
  }

  private _getLinkSubType(successLinkText, successLinkType: string, linkObject) {
    switch (successLinkType) {
      case LinkTypes.PAGE:
        return successLinkText
      case LinkTypes.LIGHTBOX:
        return linkObject.pageId
      case LinkTypes.EXTERNAL_LINK:
        return linkObject.url
      default:
        return null
    }
  }

  public async fixFormSync(componentRef: ComponentRef): Promise<void> {
    const fields: FormField[] = await this.coreApi.fields.getFieldsSortByXY(componentRef)
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    const validCollectionId = await this.coreApi.getValidCollectionId({
      componentRef,
      collectionId,
    })
    if (!validCollectionId) {
      return
    }

    const collectionFieldKeyToFields: { [key: string]: FormField[] } = fields.reduce(
      (acc, field) => {
        const collectionFieldKey = field.collectionFieldKey
        if (collectionFieldKey) {
          acc[collectionFieldKey] = (acc[collectionFieldKey] || []).concat(field)
        }
        return acc
      },
      {},
    )
    const schema = await this.coreApi.getSchema(validCollectionId)
    const fieldsToUpdate: FormField[] = fields
      .filter(({ fieldType }) => allowCollectionSync(fieldType))
      .filter((field) => {
        return (
          _.findIndex(
            collectionFieldKeyToFields[field.collectionFieldKey],
            (_field) => _field.componentRef.id === field.componentRef.id,
          ) > 0 ||
          !field.collectionFieldKey ||
          !schema.fields[field.collectionFieldKey] ||
          schema.fields[field.collectionFieldKey].isDeleted
        )
      })
    fieldsToUpdate.forEach((field) => {
      field.collectionFieldKey = this.coreApi.fields.getCollectionFieldKey(
        { crmLabel: field.crmLabel },
        fields,
      )
    })
    await Promise.all([
      this.coreApi.collectionsApi.addFieldsToCollection(validCollectionId, fieldsToUpdate),
      ...fieldsToUpdate.map((field) =>
        this.coreApi.setComponentConnection(field.componentRef, {
          collectionFieldKey: field.collectionFieldKey,
        }),
      ),
    ])
  }

  public async updateUseControllerId(componentRef: ComponentRef): Promise<void> {
    await this.coreApi.setComponentConnection(componentRef, { useControllerId: true })
  }

  public async loadRulesTabData(
    componentConnection: ComponentConnection,
  ): Promise<Partial<OwnRulesTabProps>> {
    try {
      const rulesData = await this.coreApi.rules.loadRules(componentConnection.controllerRef)

      if (rulesData.rulesUpdateStatus === RULES_UPDATE_STATUS.FAILED) {
        throw new Error('Failed to load rules initial data')
      }

      return {
        rulesData,
        rulesTabDataLoaded: PanelFieldStatus.DONE,
      }
    } catch (err) {
      return { rulesTabDataLoaded: PanelFieldStatus.FAILED }
    }
  }
}
