import _ from 'lodash'
import { safelyStringify } from '../../utils/utils'
import formsApi from './forms-api.json'
import { handleError, captureBreadcrumb } from '../forms-editor-app/monitoring'

const wrapPublicApi = (f, funcPath, { absorbException = true } = {}): Function => {
  return (...args) => {
    captureBreadcrumb({
      message: funcPath,
      category: 'core-api',
      level: 'info',
    })

    const handleApiError = (err) => {
      const stringifiedArgs = safelyStringify(args)

      handleError(err, {
        tags: { funcName: funcPath },
        extra: {
          args: stringifiedArgs,
        },
      })

      if (absorbException) {
        return null
      } else {
        throw err
      }
    }

    try {
      const funcResult = f(...args)
      if (funcResult instanceof Promise) {
        return funcResult.catch(handleApiError)
      } else {
        return funcResult
      }
    } catch (err) {
      return handleApiError(err)
    }
  }
}

const generateRuntimeApi = (obj, startObject, funcNameTransformer = (funcName) => funcName) => {
  const agr = {}
  for (let o = Object.getPrototypeOf(obj); o !== Object.prototype; o = Object.getPrototypeOf(o)) {
    _.reduce(
      Object.getOwnPropertyNames(o),
      (apiObj, funcName) => {
        const f = (o[funcName].bind && o[funcName].bind(obj)) || o[funcName]
        return !_.startsWith(funcName, '_') // expose only public methods
          ? _.merge(apiObj, {
              [funcNameTransformer(funcName)]: wrapPublicApi(f, funcNameTransformer(funcName)),
            })
          : apiObj
      },
      agr,
    )
  }
  return _.merge(startObject, agr)
}

const generateRuntimeApis = (apis) => {
  const toPublicApi = (apiName) => {
    const funcNameTransformer = (funcName) => `${apiName}.${funcName}`
    return generateRuntimeApi(apis[apiName], {}, funcNameTransformer)
  }

  const apisNames = Object.keys(apis)
  const apisFunctions = apisNames.map(toPublicApi)

  return _.assign({}, ...apisFunctions)
}

export const generateRuntimeCoreApi = (coreApi, apis) => {
  const runtimeApis = generateRuntimeApis(apis)
  return generateRuntimeApi(coreApi, runtimeApis)
}

export const generateExportedApi = (editorAppMetaData) => {
  // TODO: combine this with generateRuntimeApi from core-api, no reason we need 2 different exports implementation
  const createApiFunction = (path) => async (...payload) => {
    const api = await editorAppMetaData.getCoreApi()
    const apiFunction = path.reduce((acc, p) => {
      if (_.isFunction(acc[p])) {
        return acc[p].bind(acc)
      }
      return acc[p]
    }, api)
    return apiFunction(...payload)
  }

  const apiPaths = getAllPaths(formsApi)
  return apiPaths.reduce((acc, path) => {
    const funcPath = path.join('_')
    const f = createApiFunction(path)
    acc[funcPath] = wrapPublicApi(f, funcPath, { absorbException: false })
    return acc
  }, {})
}

const getAllPaths = (api) => {
  const paths = []
  const walk = (obj, path) => {
    path = path || []
    for (const n in obj) {
      if (!obj.hasOwnProperty(n) || n === 'default') {
        continue
      }
      if (typeof obj[n] === 'object') {
        walk(obj[n], path.concat(n))
      } else {
        paths.push(path.concat(n))
      }
    }
  }
  walk(api, [])
  return paths
}
