import _ from 'lodash'
import { AUTOMATIONS_RELEVANT_ROLES } from './constants'
import CoreApi from '../core-api'
import { NOTIFICATION_EVENTS } from '@wix/forms-common/dist/src/constants/events'
import { ROLE_FORM } from '../../../constants/roles'
import RemoteApi from '../../../panels/commons/remote-api'
import { Rule } from '@wix/ambassador-action-triggers-server/types'

export default class AutomationsApi {
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  constructor(coreApi: CoreApi, remoteApi: RemoteApi) {
    this.coreApi = coreApi
    this.remoteApi = remoteApi
  }

  public async handleFieldDeletion(
    componentRef: ComponentRef,
    primaryConnection: ComponentConnection,
    formComponentConnection: ComponentConnection,
  ) {
    const { role } = primaryConnection

    const {
      config: { plugins },
    } = formComponentConnection

    if (AUTOMATIONS_RELEVANT_ROLES.includes(role)) {
      if ((await this._getRulesByField(componentRef.id)).length > 0) {
        await this.coreApi.popNotificationAction({
          componentRef,
          notificationTrigger: NOTIFICATION_EVENTS.AUTOMATION_AFFECTED_FIELD_DELETED,
          plugins,
          hideLink: true,
        })
      }
    }
  }

  public async handleFormDeletion(
    componentRef: ComponentRef,
    primaryConnection: ComponentConnection,
  ) {
    const {
      config: { plugins },
    } = primaryConnection
    const formId = await this.coreApi.getFormId(componentRef, primaryConnection)
    const formRules = await this._getRulesByForm(formId)
    if (formRules.length > 0) {
      await this.coreApi.popNotificationAction({
        componentRef,
        notificationTrigger: NOTIFICATION_EVENTS.AUTOMATION_AFFECTED_FORM_DELETED,
        plugins,
        hideLink: true,
      })
    }
  }

  public async handleDataChanged(
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
    previousData: ComponentStructure['data'],
  ) {
    const controllerRef = _.get(componentConnection, 'controllerRef')

    if (!controllerRef) {
      return
    }
    const formComponent = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    if (!formComponent) {
      return
    }

    const {
      config: { plugins },
    } = formComponent.connection

    const field = await this.coreApi.fields.getField(componentRef)

    if (AUTOMATIONS_RELEVANT_ROLES.includes(componentConnection.role)) {
      if (
        await this._isAutomationAffectedByOptionChange(componentRef.id, field.options, previousData)
      ) {
        this.coreApi.popNotificationAction({
          componentRef,
          notificationTrigger: NOTIFICATION_EVENTS.AUTOMATION_AFFECTED_OPTION_CHANGED,
          plugins,
          hideLink: true,
        })
      }
    }
  }

  private async _getRulesByField(fieldId) {
    const formsRules = await this._getSessionFieldFormActionRules()
    const specificFieldRules = formsRules.filter((rule) => {
      const conditions = rule.trigger?.condition?.allCondition?.conditions?.find((condition) => {
        return condition.equalsToEitherCondition.key.includes(fieldId)
      })

      if (conditions) {
        return true
      }
    })

    return specificFieldRules
  }

  private async _getRulesByForm(formId) {
    const allRules = await this._getSessionFieldFormActionRules()
    const specificFormRules = allRules.filter((rule) => {
      const conditions = rule.trigger?.condition?.allCondition?.conditions?.find((condition) => {
        return (
          condition.equalsToEitherCondition.key === 'form-id' &&
          condition.equalsToEitherCondition.values.includes(formId)
        )
      })

      if (conditions) {
        return true
      }
    })

    return specificFormRules
  }

  private async _isAutomationAffectedByOptionChange(fieldId, fieldOptions, previousData) {
    const fieldsRules = await this._getRulesByField(fieldId)

    const fieldsValues = fieldOptions.map((option) => option.value)
    const oldfieldValues = previousData.options.map((option) => option.value)
    const deletedFields = _.difference(oldfieldValues, fieldsValues)

    const relevantRules = fieldsRules.filter((rule: Rule) => {
      const isConditionMissingInNewData = rule.trigger?.condition?.allCondition?.conditions?.find(
        (condition) => {
          if (condition.equalsToEitherCondition.key.includes(fieldId)) {
            return (
              _.intersection(condition.equalsToEitherCondition.values, deletedFields).length > 0
            )
          } else {
            return false
          }
        },
      )

      return !!isConditionMissingInNewData
    })

    return relevantRules.length > 0
  }

  private async _getSessionFieldFormActionRules() {
    let rules = this.coreApi.apiSessionStorage.get('fieldActionRules')
    if (!rules) {
      rules = await this.remoteApi.getWixFormsRules()

      this.coreApi.apiSessionStorage.set('fieldActionRules', rules)
    }

    return rules
  }
}
