import { get } from 'lodash'
import {
  GetRestrictionsResponse,
  Feature,
  Restrictions,
} from '@wix/ambassador-forms-v2-restrictions/types'
import { FormPlugin } from '@wix/forms-common'
import { PremiumRestriction } from '../constants/premium'

export const getFeatures = (allowedFeatures: Restrictions['allowedFeatures'] = []) => {
  return {
    downloadFile: allowedFeatures.includes(Feature.DOWNLOAD_FILE),
    generalUploadButton: allowedFeatures.includes(Feature.GENERAL_UPLOAD_BUTTON),
    generalSignature: allowedFeatures.includes(Feature.SIGNATURE_FIELD),
    paymentForm: allowedFeatures.includes(Feature.PAYMENT_FORM),
  }
}

export const mapRestrictions = (
  premiumRestrictions: GetRestrictionsResponse,
): PremiumRestriction => {
  const { restrictions } = premiumRestrictions

  const { downloadFile, generalUploadButton, generalSignature, paymentForm } = getFeatures(
    restrictions?.allowedFeatures,
  )

  const mappedRestrictions = {
    steps: {
      limit: get(restrictions, 'stepLimit.limit'),
      threshold: get(restrictions, 'stepLimit.warnThreshold'),
    },
    forms: {
      limit: get(restrictions, 'formsLimit.limit'),
      threshold: get(restrictions, 'formsLimit.warnThreshold'),
    },
    submissions: {
      limit: get(restrictions, 'submissionLimit.limit'),
      threshold: get(restrictions, 'submissionLimit.warnThreshold'),
    },
    fields: {
      limit: get(restrictions, 'fieldLimit.limit'),
      threshold: get(restrictions, 'fieldLimit.warnThreshold'),
    },
    emails: {
      limit: get(restrictions, 'emailLimit.limit'),
      threshold: get(restrictions, 'emailLimit.warnThreshold'),
    },
    rules: {
      limit: get(restrictions, 'conditionLimit.limit'),
      threshold: get(restrictions, 'conditionLimit.warnThreshold'),
    },
    allowedFields: { generalUploadButton, generalSignature },
    allowedRedirections: { downloadFile },
    allowedPlugins: { [FormPlugin.PAYMENT_FORM]: paymentForm },
  }

  return mappedRestrictions
}
