import translations from '../../../utils/translations'
import _ from 'lodash'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import { getWidgetDesignManifest } from './widget-design-manifest'
import { FormPlugin } from '@wix/forms-common'
import { getPluginAppState } from '../plugins/pluginAppStates'

const getHelpId = (plugins: FormPlugin[] = []) => {
  return _.includes(plugins, FormPlugin.REGISTRATION_FORM)
    ? GFPP.HELP_ID.REGISTRATION_FORM_CONTAINER
    : GFPP.HELP_ID.FORM_CONTAINER
}

const getGfppActions = ({ extraIconButtons }) => {
  const iconButtons = {
    [GFPP_ACTIONS.LAYOUT]: {
      actionId: GFPP_IDS.FORM_LAYOUT,
    },
    [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
    [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
    [GFPP_ACTIONS.ADD]: { actionId: GFPP_IDS.ADD_FIELD },
    ...extraIconButtons,
  }

  const mainAction1 = {
    actionId: GFPP_IDS.NEW_FORM_SETTINGS,
    label: translations.t('formSettings.gfppTitle'),
  }

  const mainAction2 = {
    actionId: GFPP_IDS.ADD_FIELD,
    label: translations.t('addField.gfppTitle'),
  }

  return { mainAction1, mainAction2, iconButtons }
}

export const createFormContainerManifest = ({
  isTopPremium,
  plugins,
  duplicatable = false,
  isKillingAscend,
}: AppStateObject = {}) => {
  const { displayNameKey } = getPluginAppState(plugins)
  const extraIconButtons = {
    ...(isTopPremium
      ? {}
      : {
          [GFPP_ACTIONS.UPGRADE]: isKillingAscend ? GFPP.REMOVE : GFPP.KEEP_DEFAULT,
        }),
  }

  const widgetDesignManifest = {
    gfpp: {
      desktop: {
        widgetDesign: getWidgetDesignManifest(),
      },
    },
  }

  const { mainAction1, mainAction2, iconButtons } = getGfppActions({
    extraIconButtons,
  })

  const manifest = {
    showFocusBox: true,
    displayName: translations.t(displayNameKey),
    behavior: { duplicatable },
    gfpp: {
      desktop: {
        mainAction1,
        mainAction2,
        iconButtons,
        helpId: getHelpId(plugins),
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FORM_CONTAINER_MOBILE,
      },
    },
  }

  return { box: _.merge({}, manifest, widgetDesignManifest), steps: null }
}
