import _ from 'lodash'
import { FormPlugin } from '@wix/forms-common'

export interface AppStateObject {
  isTopPremium?: boolean
  plugins?: FormPlugin[]
  duplicatable?: boolean
  connectFieldGfpp?: boolean
  shouldEnableManageStatesPanel?: boolean
  isResponsive?: boolean
  isKillingAscend?: boolean
}

export class AppStateBuilder {
  constructor(private stateObject: AppStateObject = {}) {}

  get = () => this.stateObject
  toString = () =>
    Object.keys(this.stateObject)
      .filter((k) => this.stateObject[k])
      .map((k) => (_.isArray(this.stateObject[k]) ? this.stateObject[k].sort().join('-') : k))
      .sort()
      .join('-')
}
