import translations from '../../../../utils/translations'
import { FieldProperties, FieldRenderConfig, PluginsFieldExtraData } from './field-types-data'
import _ from 'lodash'
import { CUSTOM_FIELD } from '../../../../constants/crm-types-tags'
import { baseStyleDefaults } from './constants'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'
import { FIELD_COMPONENT_TYPES, FieldCollectionType } from '@wix/forms-common'
import { createItemLayout } from '../../layout/responsive-utils'
import { FieldDataType } from '@wix/ambassador-contacts-v4-extended-field/types'

interface IMetadata {
  fieldType: FieldPreset
  customFields: FieldDataType[]
  mainCrmTypes: string[]
  icon: string
  ADI_titleTranslationKey: string
  allowOnlyOnce: boolean
  disableCollectionSync: boolean
  pluginsExtraData?: PluginsFieldExtraData
  renderConfig?: FieldRenderConfig
  mainRole?: string
}

export abstract class IGeneralField {
  protected translate(key, options = {}): string {
    return translations.t(key, options)
  }

  // Accessor to base class
  public abstract base: IGeneralField

  // Field Props
  public abstract fieldType: FieldPreset
  public abstract role: string
  protected abstract icon: string
  protected abstract componentType: FIELD_COMPONENT_TYPES

  public get serverType() {
    return undefined
  }

  public get subRole() {
    return ''
  }

  public get subManifest() {
    return null
  }

  public get collectionFieldType(): FieldCollectionType {
    return null
  }

  public get customFields(): FieldDataType[] {
    return [FieldDataType.TEXT]
  }

  public get mainCrmTypes(): string[] {
    return []
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  public get label() {
    return ''
  }

  public get crmTag() {
    return null
  }

  public get manifest() {
    return {}
  }

  public get designMapping() {
    return null
  }

  public get countable() {
    return true
  }

  public get acceptConditions() {
    return true
  }

  public get acceptOperations() {
    return true
  }

  public get layoutComponentProps() {
    return []
  }

  protected get data() {
    return {}
  }

  protected get props() {
    return {}
  }

  protected get layout() {
    return {}
  }

  protected get mobileHints() {
    return {}
  }

  protected get layoutResponsive(): Partial<ResponsiveLayout> {
    return {}
  }

  protected get additionalData() {
    return {}
  }
  protected get skin() {
    return null
  }

  protected get responsiveSkin() {
    return this.skin
  }

  protected get styleProperties() {
    return { properties: {} }
  }

  public get supportsLabel() {
    return false
  }

  public get supportedInResponsive() {
    return false
  }

  public get fieldWithOptions() {
    return false
  }

  public get alwaysShowLabel() {
    return false
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.fieldType}FieldEditPanel.title`
  }

  public get allowOnlyOnce() {
    return false
  }

  public get disableCollectionSync() {
    return false
  }

  public get deprecated() {
    return false
  }

  public get complexFieldWidget() {
    return false
  }

  public get revokedCommonLayoutTransformationProps() {
    return []
  }

  protected get renderConfig(): Partial<FieldRenderConfig> | null {
    return null
  }

  protected get pluginsExtraData(): Partial<PluginsFieldExtraData> | null {
    return null
  }

  get metadata(): IMetadata {
    return {
      customFields: this.customFields,
      mainCrmTypes: this.mainCrmTypes,
      icon: this.icon,
      ADI_titleTranslationKey: this.ADI_titleTranslationKey,
      allowOnlyOnce: this.allowOnlyOnce,
      disableCollectionSync: this.disableCollectionSync,
      renderConfig: this.renderConfig,
      pluginsExtraData: this.pluginsExtraData,
      fieldType: this.fieldType,
      ...(this.mainRole ? { mainRole: this.mainRole } : {}),
    }
  }

  get properties(): FieldProperties {
    const data = _.merge({}, this.base.data, this.data)
    const props = _.merge({}, this.base.props, this.props)
    const layout = _.merge({}, this.base.layout, this.layout)
    const hints = this.mobileHints

    const extraData = {
      ...(_.isEmpty(data) ? {} : { data }),
      ...(_.isEmpty(props) ? {} : { props }),
      ...(_.isEmpty(layout) ? {} : { layout }),
      ...(_.isEmpty(this.mobileHints)
        ? {}
        : {
            mobileHints: {
              ...hints,
              type: 'MobileHints',
              author: 'duplicate',
            },
          }),
      ...this.additionalData,
    }

    const connectionConfig: any = {
      ...(_.isEmpty(this.label) || !this.supportsLabel ? {} : { label: this.label }),
      ...(_.isEmpty(this.crmType) ? {} : { crmType: this.crmType }),
      ...(_.isEmpty(this.crmLabel) ? {} : { crmLabel: this.crmLabel }),
      ...(_.isEmpty(this.crmTag) ? {} : { crmTag: this.crmTag }),
      ...(_.isEmpty(this.collectionFieldType)
        ? {}
        : { collectionFieldType: this.collectionFieldType }),
      ...{ fieldType: this.fieldType },
    }

    return {
      ...(_.isEmpty(this.collectionFieldType)
        ? {}
        : { collectionFieldType: this.collectionFieldType }),
      componentType: this.componentType,
      extraData: {
        role: this.role,
        ...(this.subRole ? { subRole: this.subRole } : {}),
        ...{ connectionConfig },
        ...extraData,
      },
    }
  }

  public fieldStructure({
    isResponsive,
    responsiveItemLayoutType,
    responsiveLayoutData,
  }: {
    isResponsive?: boolean
    responsiveItemLayoutType?: ResponsiveItemLayoutType
    responsiveLayoutData?: ResponsiveLayoutData
  } = {}): Partial<RawComponentStructure> {
    const skin = isResponsive ? this.responsiveSkin : this.skin
    const baseStyle: ComponentStyle = _.assign({}, baseStyleDefaults, {
      style: this.styleProperties,
      componentClassName: this.componentType,
      skin,
    })

    const style: ComponentStyle = isResponsive
      ? {
          type: 'StylesInBreakpoint',
          skin,
          stylesInBreakpoints: [
            {
              ...baseStyle,
              breakpoint: undefined,
            },
          ],
        }
      : baseStyle

    const structure: Partial<RawComponentStructure> = {
      type: 'Component',
      skin,
      componentType: this.componentType,
      style,
      ...this.properties.extraData,
    }

    if (isResponsive) {
      const height = _.get(structure, 'layout.height')
      structure.layoutResponsive = _.merge(
        {},
        this.baseResponsiveLayout({
          height,
          itemLayoutType: responsiveItemLayoutType,
          layoutData: responsiveLayoutData,
        }),
        this.layoutResponsive,
      )
    }

    return _.cloneDeep(structure)
  }

  protected baseResponsiveLayout({
    height,
    itemLayoutType,
    layoutData,
  }: {
    height?: number
    itemLayoutType: ResponsiveItemLayoutType
    layoutData: ResponsiveLayoutData
  }) {
    return createItemLayout({
      itemLayoutType,
      height,
      layoutData,
    })
  }

  protected get mainRole(): null | string {
    return null
  }

  public getFieldLabel(field: FormField): string {
    return field?.showLabel ? field?.label : ''
  }

  public getFieldPlaceholder(field: FormField): string {
    return field?.placeholder as string
  }
}
